/* eslint-disable no-const-assign */
import React, { Component, Suspense } from 'react'
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import './../../../assets/css/requestimates.css';
import Requestimate from "./requestimate";
import RequestimateState from "./requestimateState";
import { HTTPService } from "./../../../services/httpService";
import { convertToUTC, convertFromUTC } from '../../../util/convert_timezone';
import { isIOS } from 'react-device-detect';
import ReactGA from 'react-ga4';
//import stripe from 'stripe';
const API = new HTTPService();

class Requestimates extends Component {
  

  state = {
    requestimates : [],
    estimates : [],
    requestOffers : [],
    requestStates : [],
    requestNotes : [],
    calendarDays : [],
    excludeDays : [],
    customerId : 0,
    customerName : "",
    customemrEmail : "",
    customerPhone : "",
    selectedRequestimate : {},
    selectedInstaller : "",
    invoice : {},
    activePage : 1,
    installer : {},
    selectedOffer : {},
    selectedOffers : [],
    startDate : new moment().format("YYYY/MM/DD"),
    template : "",
    materials : "",
    vin : "",
    vehicleEntered : {},
    vinGood : false,
    reviewScore : 0,
    selectedPay : "Pay Online",
    availableDate : {},
    selectedService : 0,
    services: [],
    prevMode : "",
    userType : this.props.userType,
    typeId : this.props.typeId,
    user: this.props.user,
    companyName: this.props.companyName,
    company: this.props.company,
    hiddenList: false,
    showSwipe : false,
    terminalState : "offline",
    paymentIntentId : "",
    authenticated : 0,
    loading : false,
    rowSelected : false
  };

  async componentDidMount() {

    // set the right REST call based on userType and typeId
    let installerRequest = "";
    if (this.state.userType === "dealer") {
      installerRequest = `/requestimates/getdealerrequests/?Id=${this.state.typeId}`;
    } else {
      installerRequest = `/requestimates/getinstallerrequests/?Id=${this.state.typeId}`;
    };

    const { data: requestimates } = await API.get(installerRequest);
      this.setState({ requestimates });

    // get the current calendar if user is an installer
    if ( this.state.userType === "installer" ) {

      this.readInstaller(this.state.typeId);
      let calendarRequest = `/dealerCalendars/${this.state.typeId}`;
      const { data: dealerCalendarDays } = await API.get(calendarRequest);

//      console.log("Here's the dealer work I have on the books", dealerCalendarDays);
  
      calendarRequest = `/installerCalendars/${this.state.typeId}`;
      const { data: installerCalendarDays } = await API.get(calendarRequest);
      var calendarList = dealerCalendarDays.concat(installerCalendarDays);

//      console.log("Here's the installer work I have on the books", installerCalendarDays);
      // as we can have multiple custom jobs on the calendar, need to filter out duplicates in the calendar set
      // there should only be 1 request, service with same date

      var calendarDays = _.orderBy(calendarList, ['workDate'],['asc']);

//      console.log("Here's the work I have on the books", calendarDays);

        this.setState({ calendarDays });
    
      // Create the array of the booked calendar services in the upcoming 60 days

    if (calendarDays.length > 0){
      const startDate = moment.utc().format("YYYY-MM-DD");
        
      //console.log("CC - start Date", startDate);
      var totalDuration = 60;
    
    // caalendarInWindow
      var bookedArray = calendarDays.filter(day => moment.utc(day.workDate).diff(startDate) >= 0);

      //console.log( "Upcoming calendar : ", bookedArray);    
      //console.log("CC - start Date", startDate);
      //console.log("Installer :", this.state.installer);
    
      //capacityCalendar
      var bookedData = new Array(totalDuration);

      for (var m=0; m < totalDuration; m++) {
        bookedData[m] = {'x' : m, 'exDate' : moment.utc(startDate).add(m,"days").format("YYYY-MM-DD") ,'y' : 0};
      };  

  // Need to build the array of booked capacity here for the next 60 days
      for (var k=0; k < bookedData.length; k++ ){
    // for every day in the calendar days, add up the daily_work and assign it to the right day
        let matchingWork = bookedArray.filter(work => moment.utc(work.workDate).format("YYYY-MM-DD") === bookedData[k].exDate);
        let dayTotal = matchingWork.reduce( function (result, value) {
          return result + value.capacity 
        } , 0) 
        bookedData[k].y = dayTotal;
       };

      this.setState( { excludeDays : bookedData});
//  console.log("Booked Array :", bookedData);    
      };

      let estimatesRequest = `/requestimates/getinstallerestimates/?Id=${this.state.typeId}`;        
      const  { data : estimates }  = await API.get(estimatesRequest);
//  console.log("Installer Estimates :", estimates);
      this.setState({ estimates });
  
  // combine the estimates and dealer requests into a single requestimates array

      var allRequestimates = this.state.requestimates.concat(this.state.estimates);
      allRequestimates = _.orderBy(allRequestimates,['availableDate'],['asc'])
  //console.log("All Requestimates :", allRequestimates);
      this.setState( {requestimates : allRequestimates, selectedRequestimate : ""});

      //check if authenticated 
      await this.readAuth(this.props.typeId);

      //check if the user has more than one shop to look after 
//      console.log("This is the user record in Requestimates :", this.props.user);
      await this.readCompanies(this.props.user.User_id);
    };
    // get all the responses for open requests if the user is a dealer - MOVE TO REQUEST
    if (this.state.userType === "dealer") {

      let responseRequest = `/requestOffered/${this.state.typeId}`;

      const { data: tempRequestOffers } = await API.get(responseRequest);
      const requestOffers = tempRequestOffers.filter(item => item.currentStatus === "accepted");
        this.setState({ requestOffers });      
    }
//    console.log("requestimates : all requests :", this.state.requestimates);

  };

  // Database updates when needed for each of the state changes
  readInstaller = async (installerId) => {
    let installerRequest = `/installerLocations/${installerId}`;        
    const  { data : installer }  =  await API.get(installerRequest);
//    console.log("Installer in Requestimates:", installer);
    const firstOne = installer.shift();
    this.setState({ installer : firstOne });  
  }


  readAuth = async (installerId) => {
    let installerRequest = `/installerAuth/${installerId}`;        
    const  { data : installer }  =  await API.get(installerRequest);
  //  console.log("Installer :", installer);
    this.setState( {authenticated : installer[0].authenticated});
  }

  readCompanies = async (userId) => {
    let companyList = `/userAccess/${userId}`;        
    const  { data : installer }  =  await API.get(companyList);
//    console.log("Installer Companies:", installer);
    this.setState( {companies : installer});
  }

  
  readRequestServices = async (request) => {
    let serviceRequest = `/requestService/${request}`;        
//    console.log("readRequestServices : serviceRequest : ", serviceRequest);
    const  { data : services }  =  await API.get(serviceRequest);
//    console.log("Job Services retrieved from database:", services);
    for (let i=0; i < services.length; i++){
      if (services[i].custom_description){
        services[i].Description = services[i].custom_description;
      }
    }  
    this.setState({ services });  
  }
  
  readVIN = async (vin) => {
    const vinRequest =  `/vin/?Id=${vin}`;
    const { data : vehicle } = await API.get(vinRequest);
    this.setState({ vehicleEntered : vehicle.Results[0] });  
  };
  
  readPayment = async (request) => {
    const paymentRequest =  `/installerPaymentsRequest/${request}`;
    const { data : payment } = await API.get(paymentRequest);
//    console.log ("readPayment : ", payment);
    this.setState({ payment });  
  };
  
  
  readRequestNotes = async (request) => {
    let noteRequest = `/requestnotes/${request}`;        
    const  { data : notes }  =  await API.get(noteRequest);
    this.setState({ requestNotes : notes });  
  }
  
  readRequestOffers = async (request) => {
    let offerRequest = `/requestoffered/request/${request}`;        
    const  { data : offers }  =  await API.get(offerRequest);
    this.setState({ requestOffers : offers });  
  }

  readInvoice = async (invoiceId) => {
    let invoiceRequest = `/invoices/${invoiceId}`;        
    const  { data : invoice }  =  await API.get(invoiceRequest);
    this.setState({ invoice });  
  }
  
  readRequestStates = async (request) => {
    let stateRequest = `/requeststate/${this.state.typeId}`;        
    const  { data : history }  =  await API.get(stateRequest);
    let selectHistory = history.filter(item => item.requestId === request.requestId);
    let createDate = {
      requestId : request.requestId,
      stateDate : request.createdDate,
      requestStatus : "created",
      requestService : ""
    };
    selectHistory.unshift(createDate);
  
    //console.log("Job History :", selectHistory);
    this.setState({ requestStates : selectHistory });
  }
  

  updateRequestOffered = async (offer) => {
    const apiEndpoint = `/requestOffered/${offer.requestId}`;
    const { data: post } = await API.put(apiEndpoint, offer);
//    console.log('updateRequestOffered', post);
    return null;
  };
  updateRequestOfferedState = async (offer) => {
    const apiEndpoint = `/requestoffered/state/${offer.requestId}`;
    const { data: post } = await API.put(apiEndpoint, offer);
//    console.log('updateRequestOffered', post);
    return null;
  };

  updateRequestServices = async (requestService) => {
//    console.log('updating request service');
//    console.log(requestService);
    const apiEndpoint =  `/requestServiceState/${requestService.requestId}`;
    const {data:post} = await API.put(apiEndpoint, requestService);
//    console.log(post);
    return null;
};

updateRequestAllServices = async (requestService) => {
//  console.log('updating all request service');
//  console.log(requestService);
  const apiEndpoint =  `/requestAllService/${requestService.requestId}`;
  const {data:post} = await API.put(apiEndpoint, requestService);
//  console.log(post);
  return null;
};

  updateRequest = async (request) => {
    request.createdDate = convertToUTC(request.createdDate).format("YYYY/MM/DD HH:mm:ss"); // moment(request.createdDate).format('YYYY/MM/DD HH:mm:ss');
    request.availableDate = convertToUTC(request.availableDate).format("YYYY/MM/DD HH:mm:ss"); // moment(request.availableDate).format('YYYY/MM/DD HH:mm:ss');
    request.dueDate = convertToUTC(request.dueDate).format("YYYY/MM/DD HH:mm:ss"); // moment(request.dueDate).format('YYYY/MM/DD HH:mm:ss');

    let apiEndpoint = `/requestimates/requestbyID/?Id=${request.requestId}`;
    const { data: post } = await API.put(apiEndpoint, request);
//    console.log('updateRequest : ', request);

    // Stripe Integration
    if (request.currentState === "finished"){
      const invoice = { invoiceId : request.requestId , invoiceType : request.requestType};
      apiEndpoint =  `/invoices/`;
      const {data:post} = await API.post(apiEndpoint, invoice);
      console.log ("Here's the invoice created" , post )
      // finalize the invoice 

    }
  };

  addRequestState = async (requestId, requeststate, requestService) => {
//    console.log ("Add request state :", requeststate)
    const state = {
      requestId: requestId,
      stateDate: convertToUTC(new Date()).format("YYYY/MM/DD HH:mm:ss"), // new moment().format("YYYY/MM/DD HH:mm:ss"),
      requestStatus: requeststate,
      serviceId : requestService
    };

    const apiEndpoint = `/requestState`;
    /* const { data: post } = */ await API.post(apiEndpoint, state);

    return null;

  };


  addAppointment = async (appointment) => {
  //   alert ("Add to the calendar");
 
     let readAppt = `/readAppt/${appointment}`;        
     const { data: appt } = await API.get(readAppt);
 
//     console.log("Here's the data for the appointment :", appt);
//     console.log("Here's the rest of the appointment data :", this.state.selectedRequestimate);
 //    console.log("description :", description);

    let calEntry = appt[0];

    let summary = `${calEntry.Year} ${calEntry.Make} ${calEntry.Model} ${calEntry.Service_Requested} $${calEntry.Request_Total}`;
    let description = `Customer : ${this.state.selectedRequestimate.dealerName} ${this.state.selectedRequestimate.email} ${this.state.selectedRequestimate.phone_number}`
     let newAppt = {
         request : appointment,
         summary : summary,
         description : description,
         colorId : "6",
         startDate : calEntry.work_date,
         duration : calEntry.request_duration,
         token : calEntry.refresh_token
     };
 
     let apptAdd = `/addAppt/`;        
     const { data: addAppt } = await API.put(apptAdd, newAppt);
    return;
 };
 
delAppointment = async (appointment) => {

  let readAppt = `/readAppt/${appointment}`;        
  const { data: appt } = await API.get(readAppt);

//  console.log("Here's the data for the appointment :", appt);
  
  if (appt.length > 0){
    if (appt[0].event_id){
      let newAppt = {
          eventId : appt[0].event_id,
          token : appt[0].refresh_token
      };

      let apptDel = `/delAppt/`;        
      const { data: delAppt } = await API.put(apptDel, newAppt);
  };
  };
};


  addCustomer = async (customer) => {
    //  console.log('Adding Customer Record');
    //  console.log(customer);
    
      const apiEndpoint =  `/customers`;
      const {data:customerId} = await API.post(apiEndpoint, customer);
    //  console.log(customerId);
      this.setState({customerId});
      return null;
    };
    
   updateCustomer = async (customer) => {
//    console.log('Updating Customer Record');
//    console.log(customer);
    let custRecord = {
        customerType : "Customer",
        owningInstaller : this.props.typeId
    };
    const apiEndpoint =  `/customers/${customer}`;
    const {data:customerId} = await API.put(apiEndpoint, custRecord);
//      console.log(customerId);
      return null;
    };
  
    updatePayment = async (request) => {
//      console.log('Updating Payment Record');
      const payment = {
        requestId : request.requestId,
        paymentDate : new moment().format("YYYY/MM/DD hh:mm:ss"),
        installerId : this.props.typeId,
        paymentState : "settled",
  
      };
      const apiEndpoint =  "/installerPaymentSettle/"+request.requestId;
      const {data:paypost} = await API.put(apiEndpoint, payment);
    
    };
  

  updateSchedule = async (offer, startDate) => {
//    console.log('Updating Schedule and booking');
//    console.log(offer,startDate);
    let scheduleRecord = {
        installerId : this.state.typeId,
        requestId : offer.requestId,
        installerCapacity : this.state.installer.installerCapacity,
        startDate : convertToUTC(startDate).format("MM-DD-YYYY")
    };
    const apiEndpoint =  `/schedule/`;
    const {data:scheduled} = await API.put(apiEndpoint, scheduleRecord);
//      console.log(scheduled);
  
  };
  
  
  updateVehicle = async (oldVin, newVin) => {
//     console.log('Updating vehicle record :' + oldVin + " with " + newVin);
    const vehicleEntry = {
      vin : newVin
    }
    const apiEndpoint =  `/vehiclesVIN/${oldVin}`;
    const {data:post} = await API.put(apiEndpoint, vehicleEntry);
//    console.log( "returns : ", post)
    return null;
   };
  
  updateInstallerCalendar = async (request, service, state) => {
//    console.log('Updating calendar record :', request);
//    console.log('Updating calendar record :', service);
//    console.log('Updating calendar record :', state);

    let installerId = request.installerId;
    if (typeof installerId === "undefined"){
      installerId = this.props.typeId
    };

    const calendarEntry = {
      requestId: request.requestId,
      installerId: installerId,
      serviceId : service,
      workDate: convertToUTC(new Date()).format("YYYY/MM/DD HH:mm:ss"),
      workStatus: state
    };

    const apiEndpoint = `/installerCalendarProgress/${calendarEntry.requestId}`;
    /* const { data: post } = */ await API.put(apiEndpoint, calendarEntry);

    if (service != null) {
      var calendarToChange = this.state.calendarDays;
    // find the request & service
      var dayIndex = calendarToChange.findIndex(day => (day.requestId === calendarEntry.requestId && day.serviceId === calendarEntry.serviceId));
      calendarToChange[dayIndex].workStatus = calendarEntry.workStatus;
      this.setState( {calendarDays : calendarToChange } ) ;
    };  
    return null;
    
  };


settleInvoice = async (invoice) => {
  const apiEndpoint =  "/invoices/settle/"+invoice;
  const {data:post} = await API.put(apiEndpoint);
  //console.log(post);
  return null;
};

  deleteInstallerCalendar = async (offer) => {
    const calendarEntry = {
      requestId : offer.requestId,
      installerId : offer.installerId,
    };  
    const apiEndpoint = `/installerCalendars/${offer.requestId}`;
    /* const { data: post } = */ await API.delete(apiEndpoint, calendarEntry);
    return null;

  };

  processPayment = async (payment, location) => {
  // create a new payment intent for thje terminal reader with the invoice amount on it
  // Process and capture the payment and mark the invoice as paid

//    const reader = await stripe.terminal.readers.processPaymentIntent(
 //     location,
  //    {payment_intent: payment}
    //);
  }

  resendInvoice = async (invoice) => {
    // console.log('Deleting Installer Calendar');
  //   console.log("resendInvoice : ",invoice);
     const apiEndpoint =  `/invoices/resend/${invoice}`;
     const {data:post} = await API.put(apiEndpoint);
  //   console.log("resendInvoice returned :",post);
   };


  finalizeInvoice = async (invoice) => {
    // console.log('Deleting Installer Calendar');
 //    console.log("finalizeInvoice : ",invoice);
     const apiEndpoint =  `/invoices/finalize/${invoice}`;
     const {data:post} = await API.put(apiEndpoint);
//     console.log("finalizeInvoice returned :",post);
   };


   deleteInvoice = async (invoice) => {
    // Remove the invoice if draft, and set to void if finalized
     //console.log(offer);
     let apiEndpoint =  `/invoices/${invoice}`;
     const {data:returnedInvoice} = await API.get(apiEndpoint);
 //    console.log("Returned Invoice : ", returnedInvoice);
     if (returnedInvoice.status === "draft")
     /*const {data:post} =*/ await API.delete(apiEndpoint);
     else {
      apiEndpoint =  `/invoices/void/${invoice}`;  
     /*const {data:post} =*/ await API.put(apiEndpoint);

     }

     return null;
   };
  
deletePayment = async (request) => {
  const apiEndpoint =  "/installerPayments/"+request;
  const {data:post} = await API.delete(apiEndpoint);
  //console.log(post);
  return null;
};


checkPaymentIntent = async () => {
//          console.log( "CreatePaymentIntent: This is the request ID for checking: ", this.state.selectedRequestimate.requestId);
          let paymentCheck = `/installerPaymentsRequest/${this.state.selectedRequestimate.requestId}`;
//          console.log ("CreatePaymentIntent : route " ,paymentCheck);
          const { data: intentData } = await API.get(paymentCheck);
//          console.log("Call is MADE");
//          console.log ("CreatePaymentIntent : Returned installer payment data:" ,intentData)
          return(intentData);
        };


  updateInstallerPayment = async (request, paymentRequest) => {
          //        console.log ("Here's the update records", request, paymentRequest);
                  let newPay = {
                    paymentId : paymentRequest
                  }
                  let paymentUpdate = `/installerPaymentCheck/${request}`;
                  const { data: intentData } = await API.put(paymentUpdate,newPay);          
        };
          

  createSwipe = async () => {
          let newSwipe = {
            invoiceId : this.state.invoice.id,
            locationId : this.state.installer.stripe_location,
            installerName : this.state.installer.name
          }  
//        console.log("CreateSwipe : Check the payment intent isn't already created for " , this.state.selectedRequestimate.requestId);
        let PI = await this.checkPaymentIntent();
//        console.log("CreateSwipe: Here's what we got from the installer_Payment check" , PI);
        // assuming there is always an entry
        if (!PI[0].payment_id){
        // also update the installer Payment table
        let paymentRequest = `/payments`;
          const { data: paymentRequestdata } = await API.post(paymentRequest, newSwipe);
          this.setState({paymentIntentId : paymentRequestdata});
          let newPI = this.updateInstallerPayment(this.state.selectedRequestimate.requestId, paymentRequestdata);
        } else {
          this.setState({paymentIntentId : PI[0].payment_id});
        }
//        console.log("CreateSwipe: Here's what's in paymentIntentId", this.state.paymentIntentId);
      };
    
   sendMessage = async (data) => {
    console.log("Requestimates : SendMessage :", data);
     await API.post("/messages/push", data); 
     return null;
   };
   
  // functions to handle the state changes and selections

  sendEmail = async (request) => {
     const apiEndpoint =  `/sendemailb/${request}`;
     /*const {data:post} = */ await API.get(apiEndpoint);
     //console.log(post);
     return null;
   };
  

  handlePageChange = async (pageNumber) => {
  //  console.log(`active page is ${pageNumber}`);
  //  console.log('selected service :', this.state.selectedService);
    this.setState({activePage : pageNumber});
  }
  
handlePaymentChange = async (e) => {
  let selectedPay = e.currentTarget.value;
//  console.log("Selected Pay method is :", selectedPay);

  // if the payment method selected is Swipe Card, need to create the payment intent OR read one that was previously
  // created and added to the installer payment record

  if (selectedPay === "Swipe Card"){
//    console.log ("Get the Payment Intent set up");
    let paymentIntent = this.createSwipe();
  }
  this.setState({selectedPay});
}

  handleReviewChange = async (reviewScore) => {
    this.setState({ reviewScore });
  }

  handleTerminal = async (mode) => {
    this.setState({ terminalState : mode });
  }

  handlePickStartDate = async (startDate) => {
    this.setState({ startDate });
  }

  handleCloseSwipe = async () => {
    this.setState( {showSwipe : false} )
  }

  handleVINChange = async (e) => {
    const newVin = e.currentTarget.value;
    let vin = newVin.trim().toUpperCase();
    let vinGood = false;
//    console.log('VIN Length :', vin.length);
    let good = vin.match(/[A-HJ-NPR-Z0-9|a-hj-npr-z0-9]{17}/);
    if (vin.length === 17 && good !== null) {  
      // for a dealer car, the numbers just have to match 
      // because we have already hit the NHSTA database to create the work
   //   if(this.state.selectedRequestimate.requestType === "dealer") {
   //     if(this.state.selectedRequestimate.vin === vin){
   //       vinGood = true
   //     }
   //   } else {
      // for a installer car, hit the NHSTA database to retrieve the car information
      vinGood = true
      let VINread = await this.readVIN(vin);
/*        VINread.then( () => {
          console.log("NHTSA record :",this.state.vehicleEntered.ModelYear,);      
          console.log("NHTSA record :",this.state.vehicleEntered.Make,);      
          console.log("NHTSA record :",this.state.vehicleEntered.Model,);      
          console.log("Vehicle on file :", this.state.selectedRequestimate.vehicleYear);
          console.log("Vehicle on file :", this.state.selectedRequestimate.vehicleMake);
          console.log("Vehicle on file :", this.state.selectedRequestimate.vehicleModel);
        })*/
        // need to handle the case where this isn't available
   //   }
    }
    this.setState({vin, vinGood});
  }
  

  handleMaterialsChange = async (e) => {
    const materials = e.currentTarget.value;
    this.setState({ materials });
  }

  handleTemplateChange = async (e) => {
    const template = e.currentTarget.value;
    this.setState({ template });
  }

  handleNameChange = async (e) => {
    const customerName = e.currentTarget.value;
    this.setState({customerName});
  }
  
  handleEmailChange = async (e) => {
    const customerEmail = e.currentTarget.value;
    this.setState({customerEmail});
  }
  
  handlePhoneChange = async (e) => {
    const customerPhone = e.currentTarget.value;
    this.setState({customerPhone});
  }
  
  handleDelete = async (request) => {
    // Only used for a request by an dealer with no responses
    let redirect = "reqs";
    if (request.currentState === "scheduled"){
      redirect = "jobs"
    };
  
    const offer = {
      requestId : request.requestId,
      installerId : parseInt(this.state.typeId),
      currentStatus : "deleted",
      startDate : convertToUTC(new Date()).format('YYYY/MM/DD hh:mm:ss'),
      dateUpdated : convertToUTC(new Date()).format('YYYY/MM/DD hh:mm:ss') 
    }
    
//    console.log("We're going to delete the request:",request)
    // update the database
    const updatedRequestimate = request;  
    updatedRequestimate.currentState = "deleted";
    // update the request to "deleted"
    let urequest=await this.updateRequest(updatedRequestimate);
    
    // delete the installer calendar entries if already created
    if (this.state.userType === "installer")
      await this.delAppointment(request.requestId);

    let dcalendar=await this.deleteInstallerCalendar(offer);
//    console.log("are we Here's the delete request :", request);
  
    let dpayment = this.deletePayment(request.requestId);

    // if installer deletes then remove the invoice from Stripe
  
    if (request.invoiceId) {
      this.deleteInvoice(request.invoiceId)
    };
    let uoffered=await this.updateRequestOffered(offer)
  
    // add an audit record to the table
    let astate=await this.addRequestState(request.requestId, offer.currentStatus, null);
    this.setState( {selectedRequestimate: {}} );
//    console.log("Here's the delete request :", request);
    Promise.all([uoffered,urequest,dcalendar,astate]).then(()=> {
    if (this.state.userType === "dealer") {
      ReactGA.event({category: 'Dealer', action: 'Deleted Request', label: 'prod'});
      alert("We'll let the installers who were offered the job that it is no longer available");
    } else {
      if (request.requestType === "installer") {
         ReactGA.event({category: 'Installer', action: 'Deleted Estimate', label: 'prod'});
// TODO ==== if the user is authenticated with Google Calendar and there is an eventId, then we need to remove the event from the google calendar
// need to find out if there is an event id for it
        alert("We've removed the work from your calendar");
      } else {
        ReactGA.event({category: 'Installer', action: 'Dealer Incorrect Delivery', label: 'prod'});
        alert("Contact the dealer to let them know they have sent the wrong car");
    
      }
    }
   if (redirect === "reqs") { 
      window.location = "/requestimates";
    } else {
      window.location = "/jobs";
    }
  })
  };

  iconState = (state,type) => {
    if (state === "new" && type === "installer") {
      return (<i className="fa fa-credit-card"></i>)
    } else {
    switch(state) {
        case "new": return(<i className="fa fa-comment"></i>);
        case "answered": return(<i className="fa fa-bell"></i>);
        case "scheduled": return(<i className="fa fa-calendar"></i>); 
        case "received": return(<i className="fa fa-battery-empty"></i>);
        case "started": return(<i className="fa fa-battery-half"></i>);
        case "finished": return(<i className="fa fa-car"></i>);
        case "paid" : return(<i className="fa fa-car"></i>);
        case "collected": return(<i className="fa fa-star-o"></i>);
        default: break;
    }}
  };
  
  handleCancel = async () => {
    this.setState({ selectedService : 0, selectedRequestimate: {}, hiddenList: false })
  }

  acceptCard = async () => {
    this.setState({ showSwipe : true })
  }

  handleSwipe = async () => {
    this.setState({ showSwipe : false })
  }

  handleAccept = async (request,startDate) => {
    // Only used for a request by an installer
  
    const updatedRequestimate = request;  
    // update the request_offer
    const offer = {
      requestId : request.requestId,
      installerId : parseInt(this.state.typeId),
      currentStatus : "accepted",
      startDate: convertToUTC(startDate).format("YYYY/MM/DD HH:mm:ss"),
      dateUpdated: convertToUTC(new Date()).format("YYYY/MM/DD HH:mm:ss")
    }
    updatedRequestimate.currentState = "answered";
    // update the request to "answered"
    let urequest= await this.updateRequest(updatedRequestimate);
  
    // if the date has changed, then we need to reschedule the calendar appointments
    
    if (moment(this.state.selectedOffers[0].startDate).format("YYYY-MM-DD") === moment(startDate).format("YYYY-MM-DD")) {
      // update the offer 
    await this.updateRequestOffered(offer);
  
      // update the installer Calendar - All Services for the request
    await this.updateInstallerCalendar(offer,null,"pending");

    // if the user is google authenticated, add the services to their google calendar

  
  } else {
    // remove the original calendar entries 
    await this.deleteInstallerCalendar(offer);
    // call the reschedule module
    await this.updateSchedule(offer, startDate);
  }
  
    // add an audit record to the table
    let astate= await this.addRequestState(request.requestId,offer.currentStatus, null);
    Promise.all([urequest, astate]).then(() => {
      console.log ("Handle Accept : request : ", request);
      let data = {
        messageType : "User",
        messagePlatform : "SMS",
        userType : "dealer",
        id : request.requestor,
        text : "Specialist X has responded to your request"
      }
      console.log("Send message");
      this.sendMessage(data);
  
      ReactGA.event({category: 'Installer', action: 'Answered Request', label: 'prod'});
      this.setState( {selectedRequestimate: {}} );
      alert("We'll let them know you are interested");
  })
    window.location = "/requestimates";

  };

  handleAcceptEstimate = async (request, startDate) => {
    // Only used for a estimate created by an installer
    const updatedRequestimate = request;
    
    const offer = {
      requestId : request.requestId,
      installerId : parseInt(this.state.typeId),
      currentStatus : "scheduled",
      startDate: convertToUTC(startDate).format("YYYY/MM/DD HH:mm:ss"),
      dateUpdated: convertToUTC(new Date()).format("YYYY/MM/DD HH:mm:ss")
    }
// This should set the correct parameters
    updatedRequestimate.currentState = "scheduled";
    updatedRequestimate.availableDate = offer.startDate;

    let messsageDate = moment(startDate).format("MM/DD/YYYY");

  // update the request to "scheduled"
  await this.updateRequest(updatedRequestimate);
  
  if (moment(this.state.selectedOffers[0].startDate).format("YYYY-MM-DD") === moment(startDate).format("YYYY-MM-DD")) {
      // update the offer 
    await this.updateRequestOffered(offer);

      // update the installer Calendar - All Services for the request
    await this.updateInstallerCalendar(offer,null,"booked");

// if the user is authenticated with Google, then the appointment needs to be added to the connected google calendar
//    console.log ("Are we authenticated? :", this.state.authenticated);
    if (this.state.authenticated === 1) {
      await this.addAppointment(request.requestId);
    }

  } else {
    // remove the original calendar entries 
    await this.deleteInstallerCalendar(offer);
    // call the reschedule module
    await this.updateSchedule(offer, startDate);
    if (this.state.authenticated === 1) {
      await this.addAppointment(request.requestId);
    }

  }
  // add an audit record to the table
  let states = await this.addRequestState(request.requestId,offer.currentStatus, null);

    this.setState( {selectedRequestimate: {}} );
    ReactGA.event({category: 'Installer', action: 'Booked Estimate', label: 'prod'});
    alert("We've added the work to your calendar");
//    console.log("Send Email");
    let notify = this.sendEmail(request.requestId);
    this.setState( {selectedRequestimate : ""});

    window.location = "/requestimates";
  };


handleCompleteEstimate = async (request, startDate) => {
  // Only used for a estimate created by an installer
  // Basically skips all the steps and generates the invoice so that we can go directly to pay stage

  const updatedRequestimate = request;
  
  const offer = {
    requestId : request.requestId,
    installerId : parseInt(this.state.typeId),
    currentStatus : "finished",
    startDate: convertToUTC(startDate).format("YYYY/MM/DD HH:mm:ss"),
    dateUpdated: convertToUTC(new Date()).format("YYYY/MM/DD HH:mm:ss")
  }
// This should set the correct parameters
  updatedRequestimate.currentState = "finished";
  updatedRequestimate.availableDate = offer.startDate;
//  updatedRequestimate.invoiceId = post;

// update the request to "finished" and create the invoice
await this.updateRequest(updatedRequestimate);

if (moment(this.state.selectedOffers[0].startDate).format("YYYY-MM-DD") === moment(startDate).format("YYYY-MM-DD")) {
    // update the offer 
  await this.updateRequestOffered(offer);

    // update the installer Calendar - All Services for the request
  await this.updateInstallerCalendar(offer,null,"finished");

} else {
  // remove the original calendar entries 
  await this.deleteInstallerCalendar(offer);
  // call the reschedule module
  await this.updateSchedule(offer, startDate);
}

// add an audit record to the table
let states = await this.addRequestState(request.requestId,offer.currentStatus, null);

this.setState({selectedRequestimate : ""});

  ReactGA.event({category: 'Installer', action: 'Completed Work', label: 'prod'});
  alert("Invoice Generated");
  window.location = "/pickups";
};


handleReschedule = async (request) => {
  const updatedRequestimate = request;
// console.log ("Reschedule");
  // Used for a request and an estimate by an installer
  const offer = {
    requestId : request.requestId,
    installerId : parseInt(this.state.typeId),
    currentStatus : "offered",
    startDate : new moment().format('YYYY/MM/DD hh:mm:ss' ),
    dateUpdated : new moment().format('YYYY/MM/DD hh:mm:ss') 
  }
  // update the database
  let offered = await this.updateRequestOfferedState(offer);
  // reset the request to new
  updatedRequestimate.currentState = "new"
  await this.updateRequest(updatedRequestimate);

  await this.delAppointment(request.requestId);
 
  await this.updateInstallerCalendar(offer,null,"draft");

  let astate = await this.addRequestState(request.requestId,"rescheduled", null);
  Promise.all([offered, astate]).then(() => { 
    alert("Job is reset to estimate for rescheduling");
    window.location = "/requestimates";  
})
};


handleReject = async (request) => {
  // Used for a request and an estimate by an installer

  const offer = {
    requestId : request.requestId,
    installerId : parseInt(this.state.typeId),
    currentStatus : "rejected",
      startDate: convertToUTC(new Date()).format("YYYY/MM/DD HH:mm:ss"),
      dateUpdated: convertToUTC(new Date()).format("YYYY/MM/DD HH:mm:ss")
    }

    // update the database
    let offered = await this.updateRequestOfferedState(offer);
    // delete the draft calendar entries 
      // update the installer Calendar
    let calendar = await this.deleteInstallerCalendar(offer);
//    console.log ("I think we got this far trying to figure out what's next");
  
    Promise.all([offered, calendar]).then(() => { 
//      console.log ("We are here trying to figure out what's next");
      if (request.requestType === 'dealer') {
        ReactGA.event({category: 'Installer', action: 'Rejected Request', label: 'prod'});
        alert("We'll let them know you aren't available");
      } else {
        ReactGA.event({category: 'Installer', action: 'Estimate Rejected', label: 'prod'});
        alert("I wonder why they cancelled ?");
      }
      this.setState( {selectedRequestimate: {}} );  
      
      window.location = "/requestimates"; 
    });
};
  

handleSelect = (row) => {
  this.setState( {selectedInstaller : row});
}

handleSelectService = (row) => {
//  console.log("Selected Service is :", row)
  this.setState( {selectedService : row, rowSelected : true});
}

handleSelectInstaller = (row) => {
//  console.log("Request : Here's the passed Installer : ", row)
  this.setState( {selectedInstaller : row, rowSelected : true});
}

handleAssign = async (request,installer) => {
  // Only used for a request by a dealer
// set up the rquest  

//  console.log("Assigning job to installer : ", installer);
  const updatedRequestimate = request;
  updatedRequestimate.currentState = "scheduled";
  updatedRequestimate.assignedTo = this.state.selectedInstaller.installerId;
// set up the offer
  const offer = {  
    requestId : updatedRequestimate.requestId,
    installerId : this.state.selectedInstaller.installerId,
    currentStatus : updatedRequestimate.currentState,
    dateUpdated: convertToUTC(new Date()).format("YYYY/MM/DD HH:mm:ss"),
    startDate : convertToUTC(installer.startDate).format("YYYY/MM/DD hh:mm:ss")
    };
  // update the request
  let urequest = await this.updateRequest(updatedRequestimate);
  //update the request offer
  let uoffered = await this.updateRequestOffered(offer);
  // update the installer Calendar for the specific service
  let ucalendar = await this.updateInstallerCalendar(offer, null ,"booked");
  // add an audit record to the table
  let astate = await this.addRequestState(request.requestId,updatedRequestimate.currentState, null);
  this.setState( {selectedRequestimate: {}, selectedOffer : {} });
  Promise.all([urequest,uoffered,ucalendar,astate]).then(() => {
//    console.log ("Handle Assign : request : ", request);

    let data = {
      messageType : "User",
      messagePlatform : "SMS",
      userType : "installer",
      id : updatedRequestimate.assignedTo,
      text : "Dealer has assigned you a job. https://jobvious.app"
    };
    this.sendMessage(data);

    ReactGA.event({category: 'Dealer', action: 'Assigned Work', label: 'prod'});
    alert("We'll schedule the work with your installer immediately");
    window.location = "/jobs"  
  })
};

handleReceive = async (request,vin) => {
  // Only used for a request by a dealer
  // set up the rquest  

  // update the vehicle record for the request VIN
  // if its a duplicate, then drop the NV record
  const updatedRequestimate = request;
  updatedRequestimate.currentState = "received";
  
  if (vin.length > 0) {
    await this.updateVehicle(request.vin, vin);
    updatedRequestimate.vin = vin;
  };
  
  // update the request
  let urequest = await this.updateRequest(updatedRequestimate);
    const offer = {  
      requestId : updatedRequestimate.requestId,
      installerId : parseInt(this.state.typeId),
      currentStatus : updatedRequestimate.currentState,
      dateUpdated : new moment().format("YYYY/MM/DD hh:mm:ss")
      };
    let uoffered = await this.updateRequestOfferedState(offer);
  
  // add an audit record to the table
  let astate = await this.addRequestState(request.requestId,updatedRequestimate.currentState, null);
  this.setState( {selectedOffer : {} });
  Promise.all([urequest, uoffered, astate]).then(()=>{
    if(updatedRequestimate.requestType === "dealer"){
//      console.log ("Handle Receive : request : ", request);

  //        "notification": { "alert": `${companyAPI.Installer_Name} has received ${request.vehicleYear} ${request.vehicleMake} ${request.vehicleModel}. https://jobvious.app` },
      let data = {
        messageType : "User",
        messagePlatform : "SMS",
        userType : "dealer",
        id : request.requestor,
        text : "Specialist has received your vehicle"
      }

      this.sendMessage(data);
    };
    window.location = "/jobs";
  })
};


handleStart = async (request, thisJob, service) => {
  // Only used for a job by an installer
  console.log("Handle Start", request, thisJob, service);

  const requestService = {
    requestId : request.requestId,
    serviceId : service,
    serviceState : "started",
    serviceDate : convertToUTC(new Date()).format("YYYY/MM/DD HH:mm:ss"), 
    materials : this.state.materials,
    template : this.state.template
    }
    if (request.requestType === "installer" ) {
      // mark the customer from a prospect to a customer and change the owner of the customer to the current installer
//      console.log("Update customer :", request.requestor);
      this.updateCustomer(request.requestor);
    }  
  // update the service 
  const calendar = {
    requestId : request.requestId,
    installerId : parseInt(this.state.typeId)
  }
// update the services on the original request
  let urs = await this.updateRequestServices(requestService);

// update the appointments in the installer's calendar
  let uic = await this.updateInstallerCalendar(calendar, requestService.serviceId ,"started");

// log the change on the request in the audit log
  let ars = await this.addRequestState(requestService.requestId,"start", service);
  
  // if it's the first service on the job, update the request
  // all the services in thisJob will be "booked"

  if (request.currentState === "received"){
      const updatedRequestimate = request;
      updatedRequestimate.currentState = "started";
    // update the request
      let ur = await this.updateRequest(updatedRequestimate);
            // update the offer
            const offer = {  
              requestId : updatedRequestimate.requestId,
              installerId : parseInt(this.state.typeId),
              currentStatus : updatedRequestimate.currentState,
              dateUpdated : new moment().format("YYYY/MM/DD hh:mm:ss")      };
       let uo= await this.updateRequestOfferedState(offer);
      
      // add an audit record to the table
      let aRs = await this.addRequestState(requestService.requestId,updatedRequestimate.currentState,null);
      this.setState( {selectedRequestimate: updatedRequestimate} );
      console.log ("Handle Start : request : ", request);

        if (request.requestType === "dealer") {
          let data = {
            messageType : "User",
            messagePlatform : "SMS",
            userType : "dealer",
            id : request.requestor,
            text : "Specialist X has started work on your vehicle"
          }    
          this.sendMessage(data);
        } else {
          let data = {
            messageType : "Customer",
            messagePlatform : "SMS",
            userType : "customer",
            id : request.requestor,
            text : "Specialist X has started work on your vehicle"
          }    
          this.sendMessage(data);          
        }
        ReactGA.event({category: 'Installer', action: 'Started Job', label: 'prod'});
      alert("We'll let them know you've started the work");
      window.location = "/jobs";
}

ReactGA.event({category: 'Installer', action: 'Started Service'});
this.setState( {selectedRequestimate : ""});

window.location = "/jobs";

};

handleFinish = async (request, thisJob, service) => {
  console.log("Handle Finish", request, thisJob, service);
  const requestService = {
    requestId : request.requestId,
    serviceId : service,
    serviceState : "finished",
    serviceDate : convertToUTC(new Date()).format("YYYY/MM/DD HH:mm:ss"), 
    materials : this.state.materials,
    template : this.state.template
    }


  let addReqSta = await this.addRequestState(request.requestId, "finish" , service);
  let updReqSvcrs = await this.updateRequestServices(requestService);
  let updInsCal = await this.updateInstallerCalendar(request, service, "finished");

  
  ReactGA.event({category: 'Installer', action: 'Finished Service', label: 'prod'});
//console.log("Update the service")

  // if it's the last service on the job, update the request & dealer
  // all services but 1 will be "finished"
  // update the array item with "finished"
  
  // set thisJob.object with the matching service workStatus = "finished"
/*   let serviceIndex = thisJob.findIndex(services => services.serviceId === service);
  console.log ("What's the service index of this service? :", serviceIndex);
  thisJob[serviceIndex].workStatus = "finished";
  
 var doneCount = thisJob.filter(service => service.workStatus === "finished").length;
  console.log ("Done Count for Finish :", doneCount);
  console.log("This job :", thisJob)
  if (doneCount >= (thisJob.length)|| thisJob.length === 1){
    
    let urequest=await this.handleComplete(request, thisJob);
    }
   else */
   this.setState( {selectedRequestimate : ""});

    window.location = "/jobs";   
  };

  handleComplete = async (request, thisJob) => {
    console.log("Handle Complete", request, thisJob);
    const requestService = {
      requestId : request.requestId,
      serviceState : "finished",
      serviceDate : convertToUTC(new Date()).format("YYYY/MM/DD HH:mm:ss"), 
      }
  
  
    let addReqSta = await this.addRequestState(request.requestId, "finish", null);
    let updReqSvcrs = await this.updateRequestAllServices(requestService);
//    let updInsCal = await this.updateInstallerCalendar(request, "finished");
  
    ReactGA.event({category: 'Installer', action: 'Finished Job', label: 'prod'});
  //console.log("Update the service")
  
      const updatedRequestimate = request;
      updatedRequestimate.currentState = "finished";
    // update the request
      let urequest= await this.updateRequest(updatedRequestimate);
        // add an audit record to the table
    const offer = {  
      requestId : updatedRequestimate.requestId,
      installerId : parseInt(this.state.typeId),
      currentStatus : updatedRequestimate.currentState,
      dateUpdated : new moment().format("YYYY/MM/DD hh:mm:ss")
    };
    let uoffered = await this.updateRequestOfferedState(offer);
    
    // add an audit record to the table
      let astate= await this.addRequestState(request.requestId, updatedRequestimate.currentState, null);
      this.setState( {selectedRequestimate: {}} );
    
        console.log ("Handle Complete : request : ", request);
  
        if (request.requestType === "dealer") {
          let data = {
            messageType : "User",
            messagePlatform : "SMS",
            userType : "dealer",
            id : request.requestor,
            text : "Specialist has finshed the work on your vehicle"
          }    
          this.sendMessage(data);
        } else {
          let data = {
            messageType : "Customer",
            messagePlatform : "SMS",
            userType : "customer",
            id : request.requestor,
            text : "Specialist has finshed the work on your vehicle. Check your email for your invoice"
          }    
          this.sendMessage(data);
        };
      ReactGA.event({category: 'Installer', action: 'Finished Job', label: 'prod'});
        alert("We'll let them know you have finished the work");
        window.location = "/pickups";
      };
  

handleSettle = async (request, invoice) => {
  // Only used for a job by an installer
//  console.log("Mark as paid");
//  console.log("Settle Request", request);
  if (request){
  let srequest=await this.settleInvoice(invoice);
 // add a reduced charge to the installer for settling off the platform
  let prequest=await this.updatePayment(request);
  }
// let sstate=this.addRequestState(request.requestId, "paid", null);
   ReactGA.event({category: 'Installer', action: 'Paid for Service', label: 'dev'});
   alert("We've marked the invoice as closed with payment collected");
   window.location = "/pickups";
};

handleResend = async (invoice) => {
  // Only used for a job by an installer
//  console.log("resending invoice");
  this.resendInvoice(invoice);
  alert("A copy of the invoice has been sent");
  window.location = "/pickups";
};

handleFinalize = async (invoice) => {
  // Only used for a job by an installer
//  console.log("Finalizing invoice");
  const final = await this.finalizeInvoice(invoice);
    alert("A copy of the invoice has been sent");
    window.location = "/pickups";  
};

handleDeliver = async (request) => {
  // Only used for a job by an installer  const updatedRequestimate = request;
 const updatedRequestimate = request;
 updatedRequestimate.currentState = "collected";
 // update the request
 let urequest= await this.updateRequest(updatedRequestimate);
 const offer = {  
  requestId : updatedRequestimate.requestId,
  installerId : parseInt(this.state.typeId),
  currentStatus : updatedRequestimate.currentState,
  dateUpdated : new moment().format("YYYY/MM/DD hh:mm:ss")
};
let uoffered = await this.updateRequestOfferedState(offer);

 // add an audit record to the table
 let astate= await this.addRequestState(request.requestId, updatedRequestimate.currentState, null);
 this.setState( {selectedRequestimate: {}} );
 Promise.all([urequest,uoffered,astate]).then(()=>{
   ReactGA.event({category: 'Installer', action: 'Collected Vehicle', label: 'prod'});
   window.location = "/pickups";
 })
};

handleReview = async (request) => {
  // Only used for a job by a dealer
const updatedRequestimate = request;
updatedRequestimate.currentState = "reviewed";
updatedRequestimate.reviewScore = this.state.reviewScore;
// update the request
let urequest=await this.updateRequest(updatedRequestimate);
const offer = {  
  requestId : updatedRequestimate.requestId,
  installerId : updatedRequestimate.assignedTo,
  currentStatus : updatedRequestimate.currentState,
  dateUpdated : new moment().format("YYYY/MM/DD hh:mm:ss")
};
let uoffered = await this.updateRequestOfferedState(offer);

// add an audit record to the table
let astate= await this.addRequestState(request.requestId, updatedRequestimate.currentState, null);
this.setState( {selectedRequestimate: {}, reviewScore : 0} );
Promise.all([urequest,uoffered, astate]).then(()=>{
    
  ReactGA.event({category: 'Dealer', action: 'Reviewed Job', label: 'prod'});
  alert("Thank you for the review. Reviews are critical to maintaining an installers reputation");
  window.location = "/pickups"
})
};

handlePayment = async (invoice) => {
  // Only used for a job by an installer
//  console.log ("Update the payment of the invoice in stripe :", this.state.selectedPay);
//  console.log (" for this request ", this.state.selectedRequestimate);
  if (this.state.selectedPay === "Check/Cash" || this.state.selectedPay === "Settle")
  {
  // if Cash or Settle, mark the payment as outside of Stripe
    await this.handleSettle(this.state.selectedRequestimate, this.state.invoice.id);
  };  
  if (this.state.selectedPay === "Pay Online")
  {
  // if others, display stripe page
//  console.log(this.state.invoice.hosted_invoice_url);
    window.open(this.state.invoice.hosted_invoice_url);
  };
  if (this.state.selectedPay === "Swipe Card")
  {
    await this.acceptCard();
    // console.log ("set the payment value", this.state.showSwipe);
  };
}


handleRequestimateSelect = async requestimate => {
  //also set up the list of matching responses
// get the specific services for the job & the job history
  console.log("This is what we were passed as the selected request : ", requestimate);
  
  var servs = await this.readRequestServices(requestimate.requestId);

  var stats = await this.readRequestStates(requestimate);

  var nts = await this.readRequestNotes(requestimate.requestId);

  var ofs = await this.readRequestOffers(requestimate.requestId);

  Promise.all([servs, stats, nts, ofs]).then( done =>  {
  // if the user is a dealer, then retrieve the Installer information after assigned
  if (this.state.userType === "dealer" && requestimate.assignedTo) {
     this.readInstaller(requestimate.assignedTo);
  }

  if (requestimate.invoiceId) {
    this.readInvoice(requestimate.invoiceId);
  } else {
    this.setState( {invoice : {}})
  }

  // need to wait for the promises to complete 

  //console.log("Request Offers :", this.state.requestOffers);
  //console.log("RequestOffers length", this.state.requestOffers.length)
  //console.log("Calendar Days :", this.state.calendarDays);
  //console.log("Installer : ", this.state.installer);

  let selectedOffers = this.state.requestOffers.filter(offer => (offer.requestId === requestimate.requestId));
  //console.log("Selected Offers :", selectedOffers);
  // find out what the start date of the selected job is and set it
  if (this.state.userType === "installer") {
    selectedOffers = this.state.requestOffers.filter(offer => (offer.installerId === parseInt(this.state.typeId)));
  }
  
  const jobServices = this.state.calendarDays.filter(service => (service.requestId === requestimate.requestId));
  jobServices.sort((a,b) => (a.serviceId > b.serviceId));

  let startDate = requestimate.dueDate;
  // when does start date get displayed --- for an installer on a dealer request, and for an installer estimate
  // console.log ("Job Services :", jobServices);
  //console.log("typeid :", this.state.typeId);
  if (selectedOffers.length === 1){
    startDate = selectedOffers[0].startDate;
  } 
  //console.log(selectedOffers);
  //console.log("Here's the set start date", startDate);

  let vinGood = true;

  if (requestimate.vin.substring(0,2) === "NV"){
    vinGood = false;
  } ;

//  console.log ("handleRequestimateSelect : Do I know here what vehicle ?", requestimate);
//  console.log ("handleRequestimateSelect : Do I know services ?", this.state.services);

  this.setState({selectedRequestimate: requestimate, selectedOffers, startDate, selectedService : 0, activePage : 1, vinGood });
});
}

  // =========================================================================


  // render logic to display the list, detail and various states
  render() {

    // =================================================

    const { typeId, mode } = this.props;
    const { userType } = this.state;
    ReactGA.initialize(process.env.REACT_APP_GA);

    // console.log('userType', userType)
    // console.log('typeId', typeId)
//     console.log('mode', mode)
    // console.log('this.state.requestimates', this.state.requestimates)

    // filter the list of returned information based on the mode
    let requestimateList = [];
    if (userType === "dealer" && mode === "requests") {
 			ReactGA.send({ hitType: "pageview", page: "/dealerRequests" })
      requestimateList = this.state.requestimates.filter(req => 
        ( req.currentState === "new" || 
        req.currentState === "answered" ));
      // console.log(requestimateList)
    };

    if (userType === "dealer" && mode === "jobs") {
      ReactGA.send({ hitType: "pageview", page: "/dealerJobs" })
      requestimateList = this.state.requestimates.filter(req => 
        ( req.currentState === "scheduled" || 
        req.currentState === "started" || 
        req.currentState === "received" ));
    };

    if (userType === "dealer" && mode === "pickups") { 
      ReactGA.send({ hitType: "pageview", page: "/dealerPickups" })
      requestimateList = this.state.requestimates.filter(req => 
        (req.currentState === "collected" ||
        req.currentState === "finished") )
      };

    if (userType === "installer" && mode === "requests") {
      ReactGA.send({ hitType: "pageview", page: "/installerRequests" })
      requestimateList = this.state.requestimates.filter(req => 
        (  req.currentState === "new" && 
        req.offerState === "offered"  ));
    };

    if (userType === "installer" && mode === "jobs") {
      ReactGA.send({ hitType: "pageview", page: "/installerJobs" })
      requestimateList = this.state.requestimates.filter(req => 
        ( req.currentState === "scheduled" || 
        req.currentState === "received"||
        req.currentState === "started" ));
    };

    if (userType === "installer" && mode === "pickups") { 
      ReactGA.send({ hitType: "pageview", page: "/installerPickups" })

      requestimateList = this.state.requestimates.filter(req => 
        (req.currentState === "finished") )
      };
    let namePage = "Requestimates";
    if (mode === "jobs")
      namePage = "Jobs";
    if (mode === "pickups")
      namePage = "Pickups";


    const divStyle = {
      // height: "275px",
      backgroundColor: '#FFD9AC'
    }

    if (mode === "jobs") {
      divStyle.backgroundColor = "#98CBE5";
      namePage = 'Jobs';
    };
//    console.log("Display List: ", requestimateList);
//    console.log("Selected Request: ", this.state.selectedRequestimate);
    console.log("User record  :", this.props.user);

//    let selectReq = [];
//    if (requestimateList.length > 0 && this.state.selectedRequestimate){
//      selectReq = requestimateList.filter(req => (req.requestId === this.state.selectedRequestimate.requestId))
//      if (selectReq.length === 0) {
//        this.setState({selectedRequestimate : {}});
//      }
//    }
    // if there are no items to display
    if (requestimateList.length === 0) {
      if ((mode === "requests" || mode === "jobs" || mode === "pickups") && (userType === "dealer" && this.props.user.access != "ASSIST"))
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-12 text-center">
                <h5 className="text-center">Create a new request for work</h5>
              </div>
              <div className="col-lg-4 text-center">
                <h5 className="page-name bg-request p-3 mb-0 corners" style={divStyle}>{namePage}</h5>
                <ul className="list-group-fluid p-0 list-group-flush shadow bg-white" >
                  <li className="list-group-item d-flex justify-content-center">
                    <Link to="/newrequestimates/" className="btn btn-success mt-2 mb-2">New Request</Link>
                  </li>
                </ul>
              </div>
            </div>
          </React.Fragment>
        );
      if ((mode === "requests" || mode === "jobs" || mode === "pickups") && (userType === "installer" && this.props.user.access != "ASSIST"))
//        console.log("what is the installer iformation : ", this.state.installer);
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-12 text-center">
                <h5 className="text-center">Create a new estimate</h5>
              </div>
              <div className="col-lg-4 text-center">
                <h5 className="page-name bg-request p-3 mb-0 corners" style={divStyle}>{namePage}</h5>
                <ul className="list-group-fluid p-0 list-group-flush shadow bg-white" >
                  <li className="list-group-item d-flex justify-content-center">
                    <Link to="/newestimate/" className="btn btn-success mt-2 mb-2">New Estimate</Link>
                  </li>
                </ul>
              </div>
            </div>
          </React.Fragment>
        );
      return null;
    };

    // change background color based on type of request
    const divHeight = {
      // height: "275px",
      background: "#ffffff"
    }

    // =========================================================

    function isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key))
          return false;
      }
      return true;
    }

    let screenHeader = ''
    const message = "To view more detail of a " + mode.slice(0, -1) + ", select one from the list"
    // if nothing selected, selected item isn't in the displayed list, we are done
	  if (isEmpty(this.state.selectedRequestimate)) {
		  screenHeader = message
	  } else {
      let check = [];
      check = requestimateList.filter(item => (item.requestId === this.state.selectedRequestimate.requestId));
      if (check.length === 0) {
        screenHeader = message
      } else {
        const request = this.state.selectedRequestimate;
        if (userType === "dealer") {
          switch (request.currentState) {
            case "new": screenHeader = "We are waiting for responses on this requested work"; break;
            case "answered": screenHeader = "You can assign this work to a responding installer"; break;
            case "scheduled": screenHeader = request.installerName +" is waiting for the vehicle to arrive"; break;
            case "received": screenHeader = "We see that "+ request.installerName +" has received the vehicle"; break;
            case "started": screenHeader = "We see that "+ request.installerName +" has started your job"; break;
            case "finished": screenHeader = "We see that "+ request.installerName +" has finished your job"; break;
            case "collected": screenHeader = "This completed work by "+ request.installerName +" is waiting for your review"; break;
                    default: break;
          }
        };
        if (userType === "installer") {
          switch (request.currentState) {
            case "new": screenHeader = "We are waiting for you to complete the estimate details"; break;
            case "received": screenHeader = "We will let "+ request.dealerName+ " know when you start this work"; break;
            case "scheduled": screenHeader = "We are waiting for the car from  "+ request.dealerName + " to arrive"; break;
            case "started": screenHeader = "We will let "+ request.dealerName+ " know when you finish this work"; break;
            case "finished": screenHeader = "We hope the work is done to "+ request.dealerName+ "'s satisfaction"; break;
            default: break;
          }
        };
      }
    }

    if (!_.isEmpty(this.state.selectedRequestimate)) {
      if (mode === "jobs") {
          divStyle.backgroundColor = "#98CBE5";
      } else {
        if (this.state.selectedRequestimate.requestType === "dealer"){
          divStyle.backgroundColor = "#FFD9AC"
        } else {
          divStyle.backgroundColor = "#A2E6C3"
        }
      }
    };


    return (
      <React.Fragment>
        <h3 className="page-name d-lg-none d-sm-block" style={divStyle}>
          {namePage}
          {!isEmpty(this.state.selectedRequestimate) &&
          <button type="button" className="close" data-dismiss="alert" aria-label="Close"
            onClick={() => this.handleCancel() }>
            <span aria-hidden="true">&times;</span>
          </button>}
        </h3>

        <div className="row">
          <div className="col-lg-12 text-center d-none d-lg-block">
            <h5 className="text-center">{screenHeader}</h5>
          </div>

          <div className="col-lg-4 text-center d-none d-lg-block">
            {<h5 className="page-name p-3 mb-0 corners" style={divStyle}>{namePage}</h5>}
            <ul className="list-group-fluid p-0 list-group-flush shadow bg-white corners" >
              {requestimateList.map(item => (
                <li onClick={() => this.handleRequestimateSelect(item)} key={item.requestId} className={item.requestId === this.state.selectedRequestimate.requestId ? "list-group-item active" : "list-group-item"}>
                  <small>
                    <div className="row" >
                      <div className="col-2">
                        <b>{
                          convertFromUTC(item.availableDate).format("ddd MMM D")
                        }</b>
                      </div>
                      <div className="col-8">
                        <div className="row">
                          <div className="col">
                            <div>{item.serviceRequested}</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div>{item.vehicleYear} {item.vehicleMake} {item.vehicleModel}</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            {userType === "installer" && <div>{item.dealerName}</div>}
                            {userType === "dealer" && <div>{item.installerName}</div>}
                          </div>
                          </div>
                      </div>
                      <div className="col-2">{this.iconState(item.currentState)}</div>
                    </div>
                  </small>
                </li>
                )
              )}
                { (mode === "requests" || mode === "jobs" || mode === "pickups") && (userType === "dealer"&& this.props.user.access != "ASSIST") &&
                <li className="list-group-item d-flex justify-content-center corners">
                    <Link to="/newrequestimates/" className="btn btn-success mt-2 mb-2">New Request</Link>
                </li>
                }
                { (mode === "requests" || mode === "jobs" || mode === "pickups") && (userType === "installer"&& this.props.user.access != "ASSIST") &&
                <li className="list-group-item d-flex justify-content-center corners">
                    <Link to="/newestimate/" className="btn btn-success mt-2 mb-2">New Estimate</Link>
                </li>
                }
            </ul>
          </div>

          { this.state.hiddenList===false &&
          <div className="col-lg-4 text-center d-lg-none d-sm-block">
            <ul className="list-group-fluid p-0 list-group-flush shadow bg-white" >
              {requestimateList.map(item => (
                <li onClick={() => this.handleRequestimateSelect(item, true)} key={item.requestId} className={item.requestId === this.state.selectedRequestimate.requestId ? "list-group-item active" : "list-group-item"}>
                  <small>
                    <div className="row">
                      <div className="col-2">
                        <b>{
                          /* moment(item.dueDate).format("ddd MMM D") */
                          convertToUTC(item.availableDate).format("ddd MMM D")
                        }</b>
                      </div>
                      <div className="col-8">
                        <div className="row">
                          <div className="col">
                            <div>{item.serviceRequested}</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div>{item.vehicleYear} {item.vehicleMake} {item.vehicleModel}</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            {userType === "installer" && <div>{item.dealerName}</div>}
                            {userType === "dealer" && <div>{item.installerName}</div>}
                          </div>
                        </div>
                      </div>
                      <div className="col-2">{this.iconState(item.currentState)}</div>
                    </div>
                  </small>
                </li>
                )
              )}
              { (mode === "requests" || mode === "jobs" || mode === "pickups") && (userType === "dealer"&& this.props.user.access != "ASSIST") &&
                <li className="list-group-item d-flex justify-content-center">
                    <Link to="/newrequestimates/" className="btn btn-success btn-lg mt-2 mb-2">New Request</Link>
                </li>
              }
              { (mode === "requests" || mode === "jobs" || mode === "pickups") && (userType === "installer"&& this.props.user.access != "ASSIST") && 
                <li className="list-group-item d-flex justify-content-center">
                    <Link to="/newestimate/" className="btn btn-success mt-2 mb-2">New Estimate</Link>
                </li>
                }
            </ul>
          </div>}

          {!isEmpty(this.state.selectedRequestimate) &&
          <div className="col-lg-8">
            <div className="shadow-sm p-2 corners" style={divStyle}>

              <button type="button" className="close d-none d-lg-block"
                onClick={() => this.handleCancel() }
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>

              <br/>
              <Requestimate
                selectedRequestimate={this.state.selectedRequestimate}
                calendarDays = {this.state.calendarDays}
                displayedList={requestimateList}
                offers={this.state.requestOffers}
                services ={this.state.services}
                notes = {this.state.requestNotes}
                installer = {this.state.installer}
                mode={mode}
                userType={userType}
                typeId={typeId}
                onCancel = {this.handleCancel}
              />
            </div>
            <div className="shadow-sm p-3 corners " style={divHeight}>
                <RequestimateState
                userType = {userType}
                typeId = {typeId}
                userAccess = {this.props.user.access}
                installer = {this.state.installer}
                request = {this.state.selectedRequestimate} 
                services = {this.state.services}
                notes = {this.state.requestNotes}
                displayedList = {requestimateList}
                offers = {this.state.requestOffers}
                history = {this.state.requestStates}
                invoice = {this.state.invoice}
                calendarDays = {this.state.calendarDays}
                excludeDays = {this.state.excludeDays}
                startDate = {this.state.startDate}
                paymentIntentId = {this.state.paymentIntentId}
                onStartDateChange = {this.handlePickStartDate}
                template = {this.state.template}
                onTemplateChange = {this.handleTemplateChange}
                materials = {this.state.materials}
                onMaterialsChange = {this.handleMaterialsChange}
                name = {this.state.customerName}
                email = {this.state.customerEmail}
                phone = {this.state.customerPhone}
                onNameChange = {this.handleNameChange}
                onEmailChange = {this.handleEmailChange}
                onPhoneChange = {this.handlePhoneChange}
                reviewScore = {this.state.reviewScore}
                selectedPay = {this.state.selectedPay}
                onReviewScoreChange = {this.handleReviewChange}
                onPaymentChange = {this.handlePaymentChange}
                activePage = {this.state.activePage}
                onPageChange = {this.handlePageChange}
                onDelete = {this.handleDelete}
                onAccept = {this.handleAccept}
                onAcceptEstimate = {this.handleAcceptEstimate}
                onCompleteEstimate = {this.handleCompleteEstimate}
                onReject = {this.handleReject}
                onReschedule= {this.handleReschedule}
                onSelectOffer = {this.handleSelect}
                onSelectService = {this.handleSelectService}
                onSelectInstaller = {this.handleSelectInstaller}
                selectedInstaller = {this.state.selectedInstaller}
                selectedService = {this.state.selectedService}
                rowSelected = {this.state.rowSelected}
                onAssign = {this.handleAssign}
                onReceive = {this.handleReceive}
                onResend= {this.handleResend}
                onVINChange = {this.handleVINChange}
                vin = {this.state.vin}
                vehicleEntered = {this.state.vehicleEntered}
                vinGood = {this.state.vinGood}
                onStart = {this.handleStart}
                onFinish = {this.handleFinish}
                onComplete = {this.handleComplete}
                onReview = {this.handleReview}
                onFinalize = {this.handleFinalize}
                onPayment = {this.handlePayment}
                onSettle = {this.handleSettle}
                onDeliver = {this.handleDeliver}
                onCancel = {this.handleCancel}
                showSwipe = {this.state.showSwipe}
                closeSwipe = {this.handleCloseSwipe}
                loading = {this.state.loading}
                />
            </div>
          </div>}
        </div>
      </React.Fragment>
    )
  }
}
export default withRouter(Requestimates);
