import React, { Component } from 'react';

export default class NotesForm extends Component {
    state = {
        notes: ""
    };

    handleChange = ({currentTarget : input}) => {
        console.log(input);
        this.setState( {notes : input.value});
    };

    handleAdd = () => {
        this.props.handleNotes(this.state.notes);
    };

    render() { 

        let displayServices = "";
        for (var x=0; x < this.props.services.length; x++){
            if (x > 0){
                if (x === this.props.services.length - 1)
                    displayServices = displayServices + " & ";
                else
                    displayServices = displayServices + ", ";
            };
            displayServices = displayServices + this.props.services[x].Service_Category + " " + this.props.services[x].Description
        }
  
        if ((this.props.currentStep !== 4 && this.props.userType === "dealer") || 
        (this.props.currentStep !== 4 && this.props.userType === "installer")){
            return null
        };

        return (
<React.Fragment>
<h5 className="text-center bold mb-4">Add any specific notes about the work & any additional features on vehicle that can impact price</h5>
<form className="bg-request p-5">
    <div className="form-row justify-content-center">
        <textarea className="col-6" rows="6" columns="100" autoFocus name='notes' 
                                value={this.state.notes} 
                                onChange={this.handleChange}/>
    </div>        
    <div className="form-row justify-content-md-center">
        <div className="form-group col-md-2">
            <button type="button" className="btn btn-block btn-success" onClick={this.handleAdd} disabled={this.state.notes === ""}>
            Save Notes
                </button>
        </div>
    </div>

</form>

{this.props.notes && <div><h5 className="text-center bold p-5">Work Notes</h5>
<div className="col-lg-12 bold text-center">
    <p>
    {this.props.notes}
    </p>
</div></div>}
</React.Fragment>
        );
    }


};
