/* eslint-disable no-const-assign */
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
import JOI from 'joi-browser';
import './../../../assets/css/requestimates.css';
import Referral from "./referral";
import ReferralState from "./referralState";
import { HTTPService } from "./../../../services/httpService";
import { convertToUTC, convertFromUTC } from '../../../util/convert_timezone';
import ReactGA from 'react-ga4';
//import stripe from 'stripe';
const API = new HTTPService();

class Referrals extends Component {
  

  state = {
    referrals : [],
    customerId : 0,
    customerName : "",
    customerEmail : "",
    selectedReferral : {},
    activePage : 1,
    startDate : new moment().format("YYYY/MM/DD"),
    vin : "",
    vehicleEntered : {},
    vinGood : false,
    prevMode : "",
    userType : this.props.userType,
    typeId : this.props.typeId,
    user: this.props.user,
    companyName: this.props.companyName,
    company: this.props.company,
    hiddenList: false,
    authenticated : 0,
    action : "display",
    errors : {},
    data : {
      email : "",
      name : "",
      vin :""      
    }
  };


    // set up the schema to update an entry
  schema = {
          email: JOI.string().email().required().label('email'),
          name : JOI.string().required().label('name'),
          vin : JOI.string().regex(/[A-HJ-NPR-Z0-9|a-hj-npr-z0-9]{17}/).required().label('vin')
      }; 
  

  async componentDidMount() {

    // set the right REST call based on userType and typeId
    let referralList = "";
    referralList = `/referrals/${this.state.user.User_id}`;
    
    const { data: referrals } = await API.get(referralList);
    this.setState({ referrals });
    console.log("Referrals : ", referrals);

  };

  // Database updates when needed for each of the state changes


  sendEmail = async (referral) => {
    const apiEndpoint =  `/sendemailr/${referral}`;
    /*const {data:post} = */ await API.get(apiEndpoint);
    //console.log(post);
    return null;
  };

  
  readVIN = async (vin) => {
    const vinReferral =  `/vin/?Id=${vin}`;
    const { data : vehicle } = await API.get(vinReferral);
    this.setState({ vehicleEntered : vehicle.Results[0] });  
  };
 
  addReferral = async (referral) => {  
     let apptReferral = `/referrals/`;        
     const { data: addReferral } = await API.post(apptReferral, referral);
    return addReferral;
 };
 
 addCustomer = async (customer) => {
    console.log ("Prospect Detail :", customer)
      const apiEndpoint =  `/customers`;
      const {data:customerId} = await API.post(apiEndpoint, customer);
      this.setState({customerId});
      return customerId;
    };

addVehicle = async (vehicle) => {      
        console.log("Add this vehicle :", vehicle);
        const apiEndpoint =  `/vehicles`;
        const {data:vehicleId} = await API.post(apiEndpoint, vehicle);
        this.setState({vehicleId});
        return vehicleId;
      };
 
  handleVINChange = async (e) => {
    const newVin = e.currentTarget.value;
    let vin = newVin.trim().toUpperCase();
    let vinGood = false;
//    console.log('VIN Length :', vin.length);
    let good = vin.match(/[A-HJ-NPR-Z0-9|a-hj-npr-z0-9]{17}/);
    if (vin.length === 17 && good !== null) {  
      // for a dealer car, the numbers just have to match 
      // because we have already hit the NHSTA database to create the work
   //   if(this.state.selectedReferral.referralType === "dealer") {
   //     if(this.state.selectedReferral.vin === vin){
   //       vinGood = true
   //     }
   //   } else {
      // for a installer car, hit the NHSTA database to retrieve the car information
      vinGood = true
        // need to handle the case where this isn't available
   //   }
    }
    this.setState({vin, vinGood});
  }
        // validate the field entered based on the schema
    validateProperty = ({ name, value }) => {
          const obj = { [name]: value };
          const schema = { [name]: this.schema[name] };
          const { error } = JOI.validate(obj, schema);
          return error ? error.details[0].message : null;
        };
        
    handleDataChange = ({ currentTarget: input }) => {
          const errors = { ...this.state.errors };
  //        console.log ("field validate :", input)
          const errorMessage = this.validateProperty(input);
          if (errorMessage) errors[input.name] = errorMessage;
          else delete errors[input.name];
      
          const data = { ...this.state.data };
          data[input.name] = input.value;
      
          this.setState({ data, errors });
        };
    
  handleOnClick= async () => {
    this.setState({action : "add"});

  };
  
  iconState = (state,type) => {
    return(<i className="fa fa-calendar"></i>); 
  };
  
  handleCancel = async () => {
    this.setState({ selectedService : 0, selectedReferral: {}, action : "display", hiddenList: false })
  }

  handleDelete = async () => {
    this.setState({ selectedService : 0, selectedReferral: {}, action : "display", hiddenList: false })
  }

handleSubmitReferral = async (referral) => {

// need to add the vehicle

  let VINread = await this.readVIN(this.state.data.vin);

//  console.log ("this is the vehicle : ", this.state.vehicleEntered);
  // Used for a referral and an estimate by an installer
// if the vehicle Model name contains numbers, then make it all CAPS

  let vehicleModel = "";
  if (/\d/.test(this.state.vehicleEntered.Model)) {
    vehicleModel = _.upperCase(this.state.vehicleEntered.Model)  
  } else {
    vehicleModel = _.capitalize(this.state.vehicleEntered.Model)
    }
  
    const vehicle = {
    year : this.state.vehicleEntered.ModelYear,
    make : _.capitalize(this.state.vehicleEntered.Make),
    model : vehicleModel,
    vin : this.state.vehicleEntered.VIN,
  }
  // update the database
  const { data : newVehicle } = await this.addVehicle(vehicle);

  const prospect = {
    customerName : this.state.data.name,
    customerEmailAddress : this.state.data.email,
    owningInstaller : 0
    }
  // update the database
  const { data : newCustomer} = await this.addCustomer(prospect);

  //console.log ("Returned information : Vehicle : ", this.state.vehicleId);
  //console.log ("Returned information : Prospect : ", this.state.customerId);

  const newReferral = {
    userId : this.props.user.User_id,
    customerId : this.state.customerId,
    vin : vehicle.vin,
    dateAdded : new moment().format("YYYY/MM/DD hh:mm:ss"),
    dealerId : this.props.typeId
    }

  // reset the referral to new
  let sendThis = await this.addReferral(newReferral);

  alert("Sale Added to system");

  await this.sendEmail(sendThis);

  window.location = "/referrals";  
}

handleReferralSelect = async referral => {
// also set up the list of matching responses

 console.log("This is what we were passed as the selected referral : ", referral);

  // set the data here
  
  this.setState({selectedReferral: referral, selectedService : 0, activePage : 1, vinGood : false });
};


    // ===========================================================
    // render logic to display the list, detail and various states
    // ===========================================================

render() {
    const { typeId, mode } = this.props;
    const { userType } = this.state;
    ReactGA.initialize(process.env.REACT_APP_GA);

    // filter the list of returned information based on the mode
    let referralList = [];
    if (userType === "dealer") {
 			ReactGA.send({ hitType: "pageview", page: "/dealerReferral" })
      referralList = this.state.referrals;
      // console.log(referralList)
    };
    let namePage = "Referrals"

    const divStyle = {
      // height: "275px",
      backgroundColor: '#A2E6C3'
    }
    console.log("List of Referrals : ", referralList.count);
    console.log("Selected Referral : ", !isEmpty(this.state.selectedReferral));
    console.log("Current action : ", this.state.action);


    // if there are no items to display
    if (referralList.length === 0 && this.state.action === "display") {
      if (userType === "dealer")
        return (
          <React.Fragment>
            <div className="row">
              <div className="col-lg-12 text-center">
                <h5 className="text-center">This is the list of your referral candidates for the last 90 days
                </h5>
              </div>
              <div className="col-lg-4 text-center">
                <h5 className="page-name bg-referral p-3 mb-0 corners" style={divStyle}>{namePage}</h5>
                <ul className="list-group-fluid p-0 list-group-flush shadow bg-white" >
                  <li className="list-group-item d-flex justify-content-center">
                  <button type="button" onClick={() => this.handleOnClick()} className="button btn-success m-2 buttons">New Referral</button>
                  </li>
                </ul>
              </div>
            </div>
          </React.Fragment>
        );
      return null;
    };

    // change background color based on type of referral
    const divHeight = {
      // height: "275px",
      background: "#ffffff"
    }

    // =========================================================

    function isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key))
          return false;
      }
      return true;
    }

    let screenHeader = ''
    const message = "To view more detail of a " + mode.slice(0, -1) + ", select one from the list"
    // if nothing selected, selected item isn't in the displayed list, we are done
	  if (isEmpty(this.state.selectedReferral)) {
		  screenHeader = message
	  } else {
      let check = [];
      check = referralList.filter(item => (item.referralId === this.state.selectedReferral.referralId));
      if (check.length === 0) {
        screenHeader = message
      } else {
          screenHeader = "We are waiting for this customer to investigate services";

      }
    }
    if (this.state.action === "add"){
      screenHeader = "Enter the customer information to create an Assist referral";
      }

    if (!isEmpty(this.state.selectedReferral) || this.state.action === "add") {
      if (mode === "jobs") {
          divStyle.backgroundColor = "#98CBE5";
      } else {
        if (this.state.selectedReferral.referralType === "dealer"){
          divStyle.backgroundColor = "#FFD9AC"
        } else {
          divStyle.backgroundColor = "#A2E6C3"
        }
      }
    };

console.log("referrals : ", referralList);
    return (
      <React.Fragment>
        <h3 className="page-name d-lg-none d-sm-block" style={divStyle}>
          {namePage}
          {!isEmpty(this.state.selectedReferral) &&
          <button type="button" className="close" data-dismiss="alert" aria-label="Close"
            onClick={() => this.handleCancel() }>
            <span aria-hidden="true">&times;</span>
          </button>}
        </h3>

        <div className="row">
          <div className="col-lg-12 text-center d-none d-lg-block">
            <h5 className="text-center">{screenHeader}</h5>
          </div>

          <div className="col-lg-4 text-center d-none d-lg-block">
            {<h5 className="page-name p-3 mb-0 corners" style={divStyle}>{namePage}</h5>}
            <ul className="list-group-fluid p-0 list-group-flush shadow bg-white corners" >
              {referralList.map(item => (
                <li onClick={() => this.handleReferralSelect(item)} key={item.referral_id} className={item.referral_id === this.state.selectedReferral.referral_id ? "list-group-item active" : "list-group-item"}>
                  <small>
                    <div className="row" >
                      <div className="col-2">
                        <b>{
                          convertFromUTC(item.date_added).format("ddd MMM D")
                        }</b>
                      </div>
                      <div className="col-8">
                        <div className="row">
                          <div className="col">
                            <div>{item.Year} {item.Make} {item.Model}</div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            <div>{item.customer_name}</div>
                          </div>
                          </div>
                          <div className="row">
                          <div className="col">
                            <div>{moment(item.expiration_date).diff(moment(), 'days')} days remaining</div>
                          </div>
                          </div>
                      </div>
                      <div className="col-2">{this.iconState(item.currentState)}</div>
                    </div>
                  </small>
                </li>
                )
              )}
                { (mode === "referrals" ) && userType === "dealer" &&
                <li className="list-group-item d-flex justify-content-center corners">
                    <button type="button" onClick={() => this.handleOnClick()} className="button btn-success m-2 buttons">New Referral</button>
                </li>
                }
            </ul>
          </div>

          {(!isEmpty(this.state.selectedReferral) || this.state.action === "add") &&
          <div className="col-lg-8">
            <div className="shadow-sm p-2 corners" style={divStyle}>

              <button type="button" className="close d-none d-lg-block"
                onClick={() => this.handleCancel() }
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>

              <br/>
              <Referral
                selectedReferral={this.state.selectedReferral}
                displayedList={referralList}
                mode={mode}
                userType={userType}
                typeId={typeId}
                onCancel = {this.handleCancel}
                action = {this.state.action}
              />
            </div>
              <div className="shadow-sm p-3 corners " style={divHeight}>
                  <ReferralState
                      userType = {userType}
                      typeId = {typeId}
                      referral = {this.state.selectedReferral} 
                      displayedList = {referralList}
                      startDate = {this.state.startDate}
                      errors = {this.state.errors}
                      name = {this.state.data.name}
                      onNameChange = {this.handleDataChange}
                      email = {this.state.data.email}
                      onEmailChange = {this.handleDataChange}
                      onDelete = {this.handleDelete}
                      onVINChange = {this.handleDataChange}
                      vin = {this.state.data.vin}
                      vehicleEntered = {this.state.vehicleEntered}
                      vinGood = {this.state.vinGood}
                      onSubmit= {this.handleSubmitReferral}
                      onCancel= {this.handleCancel}
                      action = {this.state.action}
                      />
                  </div>
                </div>}
        </div>
      </React.Fragment>
    )
  }
}
export default withRouter(Referrals);
