import React from 'react';
import ReactGA from 'react-ga4';
import _ from 'lodash';
import { Component } from 'react';

require('dotenv').config();


class Referral extends Component {

	state = {
		showDetails : false,
		errors : [],
	}

	isEmpty = (obj) => {
		for (var key in obj) {
			if (obj.hasOwnProperty(key))
				return false;
		}
		return true;
	}
	render() {
	if (this.props.userType === "dealer") {
			ReactGA.send({ hitType: "pageview", page: "/dealerReferralDetail" })
	};
//	console.log ("referral props : ", this.props);
	// const message = "To view more detail of a " + this.props.mode.slice(0, -1) + ", select one from the list"
	// if nothing selected, selected item isn't in the displayed list, we are done
			// otherwise display the request
	const divStyle = {
		backgroundColor: '#FFD9AC'
	}

		const referral = this.props.selectedReferral;		
		

			return (
				<React.Fragment>
                {this.props.action === "display" && <div className="row">
                        <div className="col text-center">
                            <h4>Selected Referral</h4>
                        </div>
                    </div>}
					{this.props.action === "add" && <div className="row">
                        <div className="col text-center">
                            <h4>Enter Referral Details</h4>
                        </div>
                    </div>}

				</React.Fragment>
			);
		}
}

export default Referral;
