import React from 'react';
import _ from "lodash";
import moment from 'moment';
import 'moment-timezone';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { convertToUTC, convertFromUTC } from '../../../util/convert_timezone';


const referralState = (props) => {

  const { userType, typeId, referral, displayedList, startDate, errors, 
    name, email, onNameChange, onEmailChange, onDelete, vin, vehicleEntered, vinGood, onVINChange, 
    onSubmit, onCancel, action } = props;

    function isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }
   
 // console.log ("referral State Props : ", props);

  // if nothing selected, selected item isn't in the displayed list, we are done
  if (isEmpty(referral) && action === "display") {
    return null;
  } else {

    let check = [];
    check = displayedList.filter(item => (item.referralId === referral.referralId));

    if (check.length === 0 && action === "display") {
      return null;
    } else {

   
//************************************************************************
//*******************  Dealer Referral Page ******************************
//************************************************************************


// *************************************************
// Dealer Entering a new referral for the dealership
// *************************************************
if ( action === "add" 
    && userType === "dealer") {
      console.log ("Any errors : ", errors);
return (
    <React.Fragment>
          <div className="card text-center">
            <div className="mt-4 mb-4">
              <h6>Date Vehicle Sold : {moment().format("MM/DD/YYYY")}</h6>
                <div>
                  <form>
                  <div className="form-row justify-content-md-center">
                    <div className="form-group col-lg-2 text-center">
                      <label className="bold">Enter VIN #</label>
                    </div>
                    <div className="form-group col-lg-4">
                    <input 
                        name="vin"
                        id={vin} 
                        value={vin}
                        onChange={onVINChange}
                        error={errors['vin']}/>
                        {errors['vin'] && <div className="alert alert-danger">{errors['vin']}</div>}
                      </div>
                    </div>
                    <div className="form-row justify-content-md-center">
                    <div className="form-group col-lg-2 text-center">
                      <label className="bold">Customer Name</label>
                    </div>
                    <div className="form-group col-lg-4">
                    <input 
                        name="name"
                        id={name} 
                        value={name}
                        onChange={onNameChange}
                        error={errors['name']}/>
                        {errors['name'] && <div className="alert alert-danger">{errors['name']}</div>}
                      </div>
                    </div>
                    <div className="form-row justify-content-md-center">
                    <div className="form-group col-lg-2 text-center">
                      <label className="bold">Customer Email</label>
                    </div>
                    <div className="form-group col-lg-4">
                    <input 
                        name="email"
                        id={email} 
                        value={email}
                        onChange={onEmailChange}
                        error={errors['email']}/>
                        {errors['email'] && <div className="alert alert-danger">{errors['email']}</div>}
                      </div>
                    </div>
                </form>
                </div>
              
              <button type="button" onClick={onSubmit} className="button btn-success m-2 buttons" disabled={!isEmpty(errors) && !vinGood} >ADD</button>
              <button type="button" onClick={() => onCancel(referral)} className="button m-2 buttons">CANCEL</button>
          </div>
        </div>
    </React.Fragment>
  )}

// *************************************************
// Dealer display of an existng referral
// *************************************************
else {

return (
    <React.Fragment>
          <div className="card text-center">
            <div className="mt-4 mb-4">
                <h6>Sold date : {moment(referral.availableDate).format("MM/DD/YYYY")}</h6>
                <div>
                    <h6>VIN : {referral.VIN}</h6>
                </div>
                <div>
                  <p>{referral.Year} {referral.Make} {referral.Model} </p>
                </div>
                <div>
                  <h6>Customer Email : {referral.customer_Email_Address} </h6>
                </div>
                <div>
                  <h6>Customer Name : {referral.customer_name} </h6>
                </div>
              <button type="button" onClick={() => onCancel(referral)} className="button btn-success m-2 buttons">CANCEL</button>
          </div>
        </div>
    </React.Fragment>
  )};
    }
  }
};


export default referralState;
