import React, { Component } from 'react';
import Select from './common/select2';
import {AddCustomCut} from './partial/addCustomCut'
import {AddDiscount} from './partial/addDiscount';
import { SelectCustomerJob } from './partial/selectCustomerJob';


import BootstrapTable from 'react-bootstrap-table-next';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { HTTPService } from "./../services/httpService";

import _ from 'lodash';

const API = new HTTPService()

export default class ServiceForm extends Component {

    state = {services : [] ,
        errors : {},
        selectedServices : [],
        categories : [],
        selectedService : {},
        selectedCategory : {},
        selectedDiscount : 0,
        displayServices : [],
        totalCost : parseFloat(this.props.total),
        totalDiscounted : 0,
        discount : this.props.discount,
        totalDuration : 0,
        selectedServicePrice : "",
        message : "Requested Work",
        showCustom : false,
        showDisc : false,
        estimateDesc : "",
        estimateCost : 0,
        estimateDuration : 0,
        custJobs : []

    }

    // this will return the services offered
    async componentDidUpdate(prevProps) {
//        console.log("prevProps and props :", prevProps.vehicle, this.props.vehicle)
        if ((this.props.vehicle !== prevProps.vehicle) || (this.props.customerType !== prevProps.customerType)){

        var servicesRequest =  this.props.typeId + "/" + this.props.vehicle.size+ "/" + _.upperCase(this.props.customerType);

        // read the correct services based on the type of work being entered

//        console.log("ComponentDidUpdate : specialist :", this.props.specialist);

        if (this.props.userType === "dealer"){
//            servicesRequest =  "0/M/WHOLE"
            servicesRequest =  this.props.specialist + "/" + this.props.vehicle.size+ "/WHOLE";
          };
//          console.log("ComponentDidUpdate : servicesRequest :", servicesRequest);

        // Fetch the services
        const { data: allServices } = await API.get(`/services/${servicesRequest}`);

        let categories = [...new Set(allServices.map(x => x.Service_Category))];
        let services = [...new Set(allServices)];

        console.log ("ServiceForm : Services :", allServices);

        // filter custom services if dealer 
        if (this.props.userType === "dealer"){
            services = services.filter((service) => service.Description !== "Custom");
        };

        this.setState({ services, categories, selectedService: '' });

        // also go and see if there are any previous jobs for that vin number for this installer
        if (this.props.vehicle && this.props.userType === "installer") {
        var jobsRequest =  this.props.typeId + "/" + this.props.vehicle.vin;

//        console.log ( "Service Form : Vehicles :", this.props.vehicle);
        // Fetch the jobs
        const { data: jobs } = await API.get(`/requestimatesVIN/${jobsRequest}`);

//        console.log ("Service Form : Selected jobs for VIN : ", jobs);

        this.setState({ custJobs : jobs, selectedJob: '' });

//        console.log ("Service Form : Selected jobs for VIN : ", jobs.length);

        if (jobs.length > 0 && this.props.customerType === "retail") 
          this.setState({ showJobList : true});
        }
        }
    };

    
    addService = () => {
        //add the service to the list
        let selectedServices = Array.from(this.state.selectedServices);

        // Is the service already in the list, then ignore the action 

        this.setState( {message : "NOTE : Price may vary based on selected model", showCustom : false} );

        if (!selectedServices.find(svc => (svc.Service_id === this.state.selectedService.Service_id)))
        {
            let totalCost = this.state.totalCost;
            let totalDuration = this.state.totalDuration;
            let discount = this.state.discount;
            let totalDiscounted = this.state.totalDiscounted;
            let goodToGo = 0;
            // Is the service an override for one that's already in the list, if so, remove the other one and add this one    
            if (this.state.selectedService.overrides != null) 
            {
                const parentServices = this.state.selectedService.overrides.split(',');
                for (let x=0; x < parentServices.length; x++){
                    let selectedServicesIDs= this.state.selectedServices.map(id => id.Service_id);
                    let foundIndex = selectedServicesIDs.indexOf(parseInt(parentServices[x],10));
                    if ( foundIndex > -1){
                        totalCost = totalCost - selectedServices[foundIndex].Service_cost;
                        discount = totalCost * this.state.selectedDiscount/100;
                        totalDiscounted = totalCost - discount;             
                        totalDuration = totalDuration - selectedServices[foundIndex].duration_days;
                        selectedServices.splice(foundIndex,1); 
                    }
                    this.setState( {message : "NOTE : Price may vary based on selected model"} );

                }

            } else {
// otherwise, see if its already , message that its already in there   

                for (let x=0; x < selectedServices.length; x++) {
                    if (selectedServices[x].overrides != null) {
                        const childServices = selectedServices[x].overrides.split(',');
                        let found = childServices.indexOf(this.state.selectedService.Service_id.toString());
                        if (found > -1) {
                            goodToGo = 1;
                            this.setState( {message : "Already included in a selected service"} );
                        }
                    }
                }
            }
            if (goodToGo === 0) {
                let serviceSelected = {
                    Description : this.state.selectedService.Description,
                    Service_Category : this.state.selectedService.Service_Category,
                    Service_cost : this.state.selectedService.Service_cost,
                    Service_Discount : 0,
                    Service_Amount : this.state.selectedService.Service_cost,
                    Service_id : this.state.selectedService.Service_id,
                    data_collect : this.state.selectedService.data_collect,
                    duration_days : this.state.selectedService.duration_days,
                    installer_category : this.state.selectedService.installer_category,
                    installer_id : this.state.selectedService.installer_id,
                    market_size : this.state.selectedService.market_size,
                    overrides : this.state.selectedService.overrides,
                    vehicle_size : this.state.selectedService.vehicle_size
                };               
                console.log("selected Service:", serviceSelected);
                console.log("Discount:", this.state.selectedDiscount);
                serviceSelected.Service_Discount = serviceSelected.Service_cost * this.state.selectedDiscount/100;
                serviceSelected.Service_Amount = serviceSelected.Service_Amount - serviceSelected.Service_Discount;
                selectedServices.push(serviceSelected);
                this.setState({selectedServices});
                totalCost = totalCost + this.state.selectedService.Service_cost;
                discount = totalCost * this.state.selectedDiscount/100;
                totalDiscounted = totalCost - discount;
                this.setState( {totalCost, totalDiscounted});
                totalDuration = totalDuration + this.state.selectedService.duration_days;
                this.setState( {totalDuration});
                this.props.handleServices(selectedServices, totalCost, this.state.selectedDiscount);
                if (serviceSelected.Service_cost === 0){
                    this.setState( {showCustom : true});
                }

            }
        }   
}


calculateTotals = () => {
    //take the list of selected services and calculate the totals
    let totalCost = 0;
    let totalDiscounted = 0;
    let discount = 0;
    let totalDuration = 0;
//    console.log("How many services do we have :", this.state.selectedServices.length);
    if (this.state.selectedServices.length > 0) {
        totalCost = this.state.selectedServices.map(x => x.Service_cost).reduce((a,c) => a + c);
//        console.log ("Total Cost :", totalCost);
        discount = totalCost * this.state.selectedDiscount/100;
        totalDiscounted = totalCost - discount;
        totalDuration = this.state.selectedServices.map(x => x.duration_days).reduce((a,c) => a + c);
//        console.log ("Total Duration :", totalDuration);
    }
        this.setState( {totalCost , totalDiscounted});
        this.setState( {totalDuration});
        this.setState( {estimateCost : 0, estimateDuration : 0});
//        console.log ("Full Array :", this.state.selectedServices)
        this.props.handleServices(this.state.selectedServices,totalCost,this.state.selectedDiscount);
    }


    removeService = (row, rowIndex) => {
          //remove the service to the list
          let selectedServices = Array.from(this.state.selectedServices);
          selectedServices.splice(rowIndex,1);
          this.setState({selectedServices});
          const totalCost = this.state.totalCost - parseFloat(row.Service_Amount.replace(/\$|,/g, ''));
          const discount = totalCost * this.state.selectedDiscount/100;
          const totalDiscounted = totalCost - discount;  
          this.setState( {totalCost, totalDiscounted});
          const totalDuration = this.state.totalDuration - row.duration_days;
          this.setState( {totalDuration, estimateCost : 0, estimateDuration : 0});
          this.props.handleServices(selectedServices, totalCost, this.state.selectedDiscount);  
        }

    handleCustomEstimate = (desc, cost, duration) => {
        let estimateDesc = desc;
        let estimateCost = parseFloat(cost);
        let estimateDuration = parseFloat(duration);
//        console.log("We got to update ", estimateDesc, estimateCost, estimateDuration)
        // update the selected item
        let selectedServices = [ ...this.state.selectedServices ];
        let index = selectedServices.findIndex(item => (item.Service_cost === 0));
//        console.log("Found at :", index);
        if (index > -1) {
            selectedServices[index].Description = estimateDesc;
            selectedServices[index].Service_cost = estimateCost;
            selectedServices[index].duration_days = estimateDuration;
            selectedServices[index].Service_Discount = selectedServices[index].Service_cost * this.state.selectedDiscount/100;
            selectedServices[index].Service_Amount = selectedServices[index].Service_cost - selectedServices[index].Service_Discount;
        }
 //       console.log ("Post Services :", selectedServices);
        // recalculate the totals 
        this.setState( {selectedServices, estimateDesc, estimateCost, estimateDuration, showCustom : false});
        this.calculateTotals();
    }
    

    handleCategoryChange = (event) => {

        this.setState({ selectedService: {Description: ''}, displayServices: []});

        const { value } = event.target;

        const dispServices = this.state.services.filter(service => service.Service_Category === value);
        const displayServices = dispServices.map(item => item.Description);

        this.setState({ displayServices, selectedCategory: value, estimateCost : 0, estimateDuration : 0 , estimateDesc : ""});
        
    }

    handleServiceChange = (event) => {

        const { value } = event.target;
        const service = this.state.services.find(service => ( service.Description === value && service.Service_Category === this.state.selectedCategory ));
        this.setState({ selectedService: service });

    }

    displayDisc = () => {
        this.setState( {showDisc : true})
    }

    removeDisc = () => {
        let total = this.state.totalCost;
//        console.log("Here's the data in State :", this.state)
                // recalculate service discounts
                let invoiceServices = Array.from(this.state.selectedServices);
                for (let i=0; i < invoiceServices.length; i++){
                    invoiceServices[i].Service_Discount = 0;
                    invoiceServices[i].Service_Amount = invoiceServices[i].Service_cost;
                }
        
        this.setState({selectedDiscount : 0, discount : 0, totalDiscounted : total, selectedServices : invoiceServices})
        this.props.handleServices(invoiceServices, this.state.totalCost, 0);

    }

    handleDiscountChange = (discountPercent) => {
        // recalculate service discounts
        let invoiceServices = Array.from(this.state.selectedServices);
        for (let i=0; i < invoiceServices.length; i++){
            invoiceServices[i].Service_Discount = invoiceServices[i].Service_cost * discountPercent / 100;
            invoiceServices[i].Service_Amount = invoiceServices[i].Service_Amount - invoiceServices[i].Service_Discount;
        }
        console.log("invoice services :", invoiceServices);
        console.log("State :", this.state);
        let discount = this.state.totalCost * discountPercent / 100;
        const totalDiscounted = this.state.totalCost - discount;
        this.setState( {selectedDiscount : discountPercent , totalDiscounted, discount,showDisc : false, selectedServices : invoiceServices});
        this.props.handleServices(this.state.selectedServices, this.state.totalCost, discountPercent)
    }

    addCustomClose = () => {
        // find the item in the selected list where the cost = 0 and remove it
            let selectedServices = [ ...this.state.selectedServices ];
            let index = selectedServices.findIndex(item => (item.Service_cost === 0));
            if (index > -1) {
                selectedServices.splice(index, 1);
            }
            this.setState( {showCustom : false, selectedServices, showDisc : false});
        };


    handleJobSelect = async (selectRequest) => {
    // Populate the selected list with the items in the job
    // read each service from the job selected and call addService for each
        console.log( "Service Form : Select Request : ", selectRequest);
        const { data: services } = await API.get(`/requestServices/${selectRequest.request_id}`);
        console.log("service Form : Selected Services : ", services);

        for (let i= 0; i < services.length; i++ ){
            this.setState( {selectedService : services[i]});
            this.addService();            
        }
    // then close the window

    this.setState( {showJobList : false});
    };
    

    handleClose = () => {
        // find the item in the selected list where the cost = 0 and remove it
        this.setState( {showJobList : false});
    };
    

    render() {
        
        const columns = [{
            dataField: 'Service_Category',
            text: "Category"
        }, {
            dataField: 'Description',
            text: "Service"
        }, {
            dataField: 'Service_Amount',
            text: "Amount"
        }, {
            dataField: 'duration_days',
            text: "Duration"
        }];

          

     //   console.log("currentStep & userType", this.props.currentStep, this.props.userType);
        
        if ((this.props.currentStep !== 3 && this.props.userType === "dealer") || 
        (this.props.currentStep !== 3 && this.props.userType === "installer")){
            return null
        }

        const selectRow = { mode: 'radio',
        onSelect: (row, isSelect, rowIndex, e) => {this.removeService(row, rowIndex)}} ;
  
        let selectedServices = [ ...this.state.selectedServices ];
        selectedServices = selectedServices.map(selectedService => {
            let selectedService_ = Object.assign({}, selectedService);
            selectedService_.Service_Amount = "$"+selectedService_.Service_Amount.toFixed(2);
            return selectedService_;
        });
        
        return (
            <React.Fragment>
                <form className="bg-request p-5">
                    <div className="form-row justify-content-md-center">
                        <div className="form-group col-md-2 text-center">
                            <label htmlFor="vin" className="bold">Pick Service(s)</label>
                        </div>
                        <div className="form-group col-md-3">
                            <Select
                                name="category"
                                label=" "
                                options={this.state.categories}
                                value={this.state.selectedCategory}
                                onChange={this.handleCategoryChange}
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <Select
                                name="service"
                                label=" "
                                options={this.state.displayServices}
                                value={this.state.selectedService.Description}
                                onChange={this.handleServiceChange}
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <button className="btn btn-block btn-success" type="button" disabled={!this.state.selectedService.Description}
                            onClick={this.addService}>
                                Add Service
                            </button>
                        </div>
                        <AddCustomCut
                        show={this.state.showCustom}
                        onHide={this.addCustomClose}
                        estimateDesc={this.state.estimateDesc}
                        estimateCost={this.state.estimateCost}
                        estimateDuration={this.state.estimateDuration}
                        handleCustomEstimate={this.handleCustomEstimate}
                        addService={this.addService}
                        />
                        <AddDiscount
                        show={this.state.showDisc}
                        onHide={this.addCustomClose}
                        discountValue={this.state.discount}
                        jobTotal={this.state.totalCost}
                        handleDisc={this.handleDiscountChange}
                        />

                    </div>
                { this.props.userType !== "dealer" && this.state.selectedServices.length > 0 && 
                    <div className="form-row justify-content-md-center">
                        <div className="form-group col-md-4">
                            <button 
                                className="btn shadow btn-success" 
                                disabled={!this.state.selectedService.Description && this.state.selectDiscount}
                                type="button" onClick={this.displayDisc}>
                                Add Discount
                            </button>
                            <button 
                                className="btn shadow btn-success" 
                                disabled={!this.state.selectedDiscount}
                                type="button" onClick={this.removeDisc}>
                                Remove Discount
                            </button>
                        </div>
                        </div>}

                </form>

                {this.state.selectedServices.length > 0 && <div>
                <h5 className="text-center bold p-5">{this.state.message}</h5>
                <div className="text-center table-responsive">
                    <BootstrapTable keyField='Service_id' data={selectedServices} columns={columns} selectRow= {selectRow}/>
                </div>
                <div className="row">
                    <div className="col text-right">
                        {this.state.selectedServices && <div>Total Cost: ${this.state.totalDiscounted.toFixed(2)}</div>}
                    </div>
                    {this.state.discount > 0 && <div className="col text-center"> Discount Applied : ${this.state.discount.toFixed(2)}</div>}
                    <div className="col text-left">
                        {this.state.selectedServices && <div>Service Duration: {this.state.totalDuration} days</div>}
                    </div>
                </div>
                </div>}
                <SelectCustomerJob
                    showJobList = {this.state.showJobList}
                    onHide = {this.handleClose}
                    jobs = {this.state.custJobs}
                    onSelect = {this.handleJobSelect}
                    mode = "select"
                />

            </React.Fragment>
        );
    };

};
