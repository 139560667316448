import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Requestimates from './components/views/requestimates/requestimates';
import Referrals from './components/views/referrals/referrals';
import RequestimateForm from "./components/views/requestimates/requestimateForm";
import NavBar from './components/partial/navBar';
import NavTop from './components/partial/navTop';
import NavTopBar from './components/partial/navTopBar';
import Calendar from './components/views/calendar/calendar';
import Financials from './components/views/financials/financials';
import Vehicles from './components/vehicles';
import Customers from './components/views/customer/customers';
import Services from './components/views/services/services';
import WalkInForm from "./components/views/requestimates/walkInForm";
import './assets/css/App.css';
import ReactGA from 'react-ga4';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import { HTTPService } from "./services/httpService";

const API = new HTTPService();

require('dotenv').config();

class App extends Component {

  // eslint-disable-next-line no-useless-constructor
  constructor(props, context) {
    super(props, context);
  }


  handleChange = async (newCompany) => {
    
    let response = await API.get(`/installers/${newCompany}`)
                            
      // Company installer
      const company = response.data;
      const companyName = company[0].Installer_Name.replace(/ /g,"_").toLowerCase();
      localStorage.setItem('companyAPI', JSON.stringify(company[0]));
      localStorage.setItem('named_user_id', JSON.stringify(companyName));

      window.location = '/requestimates';

  };


  render() {
    ReactGA.initialize(process.env.REACT_APP_GA);
    const isProduction = true;
    setTimeout(() => {

      // console.log("window.localStorage.getItem('amplify-authenticator-authState'): ");
      // console.log(window.localStorage.getItem('amplify-authenticator-authState'));

      // Checks if login process has finished, if not, returns to login
      if ( ( !window.localStorage.getItem('userAPI') || !window.localStorage.getItem('companyAPI') ) && ( window.localStorage.getItem('amplify-authenticator-authState') === 'signedIn' ) ) {
          window.localStorage.clear();
          window.location.reload();
      }

    }, 5000);

    const user = JSON.parse(localStorage.getItem('userAPI'));
    const company = JSON.parse(localStorage.getItem('companyAPI'));

    if (this.props.authState === "signedIn" && user && company) {
      
      let companyName = '';
      let typeId = '';
      let capacity = 0;
      let authenticated = 0;
      const userType = user.User_type;
//      console.log("Here's the user record : ", user);
//      console.log("Here's the company record", company);

      if (userType === 'dealer') {
        companyName = company.dealer_name;
        typeId = company.dealer_id;
      } else {
        companyName = company.Installer_Name;
        typeId = company.Installer_id;
        capacity = company.capacity;
        authenticated = company.Authenticated;
      }

      const channel = JSON.parse(localStorage.getItem('X3VhX3NkazoxejB6ckxKNVJLLXNid3N0NG9VbGZROi9wdXNoLXdvcmtlci5qcw==:channel'));

      const channel_id = channel ? channel.channel_id : null;
      if ( !channel_id ) {

        let payload = {
          body: 'Please, take a look if your browser is supported from Urban Airship notifications.',
          icon: 'iPhone+512.png',
        };

//        if ( !isIOS ) {
//          new Notification("Notifications are disabled.", payload);
//        }
        
      }

      const selectedRequest = {};
      const selectedReferral = {};

      // check to see if the user has authenticated since logging in

      return (
        <CacheBuster
        currentVersion={version}
        isEnabled={isProduction} //If false, the library is disabled.
        isVerboseMode={true} //If true, the library writes verbose logs to console.
        metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.  
        >
        <React.Fragment>
          <BrowserRouter>
            <div className="App">
              <div className="container-fluid">

                <div className="row">
                  {/* Mobile */}
                  <div className="col-lg-12 d-lg-none p-0">
                    <NavTopBar title={companyName} userType={userType} {...this.props} />
                  </div>

                  {/* Display LG */}
                  <div className="col-lg-2 bg-sidebar p-0 d-none d-lg-block">
                    <NavBar userType={userType} userId={user.User_id} entityId={typeId} authenticated={authenticated} {...this.props} />
                  </div>

                  <div className="col-lg-10 p-0 wrapper">
                    <NavTop title={companyName} userId={user.User_id} userName={user.user_name} onSwitch={this.handleChange} userType={userType} />
                    {/* Display LG */}

                    <div className="p-2">
                      <Switch>
                        <Route path="/calendar" render={props =>
                          <Calendar userType={userType} typeId={typeId} authenticated={authenticated} {...props} />
                        } />
                        <Route path="/financials" render={props =>
                          <Financials userType={userType} typeId={typeId} {...props} />
                        } />
                        <Route path="/customers" render={props =>
                          <Customers userType={userType} typeId={typeId} {...props} />
                        } />
                        <Route path="/services" render={props =>
                          <Services userType={userType} typeId={typeId} {...props} />
                        } />
                        <Route path="/vehicles" render={props =>
                          <Vehicles userType={userType} typeId={typeId} {...props} />
                        } />
                        <Route path="/jobs" render={props =>
                          <Requestimates mode='jobs' userType={userType} user={user} companyName={companyName} company={company} authenticated={authenticated} typeId={typeId} selectedRequest={selectedRequest} {...props} />
                        } />
                        <Route path="/pickups" render={props =>
                          <Requestimates mode='pickups' userType={userType} user={user} companyName={companyName} company={company} typeId={typeId} selectedRequest={selectedRequest} {...props} />
                        } />
                        <Route path="/newrequestimates" render={props =>
                          <RequestimateForm userType={userType} user={user} companyName={companyName} company={company} typeId={typeId} {...props} />
                        } />
                        <Route path="/newestimate" render={props =>
                          <WalkInForm userType={userType} user={user} capacity = {capacity} companyName={companyName} company={company} typeId={typeId} {...props} />
                        } />
                        <Route path="/requestimates" render={props =>
                          <Requestimates mode="requests" userType={userType} user={user} typeId={typeId} companyName={companyName} authenticated={authenticated} selectedRequest={selectedRequest} {...props} />
                        } />
                        <Route path="/referrals" render={props =>
                          <Referrals mode="referrals" userType={userType} user={user} typeId={typeId} companyName={companyName} authenticated={authenticated} selectedReferral={selectedReferral} {...props} />
                        } />
                      </Switch>
                    </div>

                    <div className="col-lg-12 mt-5 text-center">
                      <p>&copy; 2025 Jobvious, Inc </p>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </BrowserRouter>
        </React.Fragment>
        </CacheBuster>
      );
    } else {
      return null;
    }
  }
}

export default App;
